import { CodeSnippetTemplateIds } from '../snippets'

export interface CollectionDynamicVariables {
  indexName?: string
  indexId?: number
  datasetName?: string
  vector?: string
  randomIdVectorPair?: string
  url?: string
  apiKey?: string
  body?: string
  host?: string
  endpoint?: string
  contentLength?: string
  baseUrl?: string
  method?: string
}

/**
 * This function replaces the variables in the text with the values provided in the variables object
 *
 * @param text
 * @param variables
 * @returns {String}
 */
export function replaceStaticVariables(text: string, variables: CollectionDynamicVariables): string {
  // @ts-ignore
  return text.replace(/{{\s*([^\s{}]+)\s*}}/g, (_: any, variable: keyof CollectionDynamicVariables) => {
    const value = variables?.[variable]

    return value ?? ''
  })
}

/**
 * This function replaces the variables in the text with the values provided in the variables object
 *
 * @param text
 * @param variables
 * @returns {String}
 */
export function replaceStaticVariablesFromUrl(text: string, variables: CollectionDynamicVariables): string {
  // @ts-ignore
  return text.replace(/{\s*([^\s{}]+)\s*}/g, (_: any, variable: keyof CollectionDynamicVariables) => {
    const value = variables?.[variable]

    return value ?? ''
  })
}

export const isEscapeCharBodyLang = (snippet: CodeSnippetTemplateIds) =>
  [
    CodeSnippetTemplateIds.Curl,
    CodeSnippetTemplateIds.Clojure,
    CodeSnippetTemplateIds.CSharpHttpClient,
    CodeSnippetTemplateIds.CSharpRestSharp,
    CodeSnippetTemplateIds.Go,
    CodeSnippetTemplateIds.JavaAsyncHTTPClient,
    CodeSnippetTemplateIds.JavaNetHttp,
    CodeSnippetTemplateIds.JavaOkHttp,
    CodeSnippetTemplateIds.JavaUnirest,
    CodeSnippetTemplateIds.KotlinOkHttp,
    CodeSnippetTemplateIds.OCamlCohttp,
    CodeSnippetTemplateIds.PHPCurl,
    CodeSnippetTemplateIds.PythonNative,
    CodeSnippetTemplateIds.RHttr,
    CodeSnippetTemplateIds.RubyNative,
  ].includes(snippet)
