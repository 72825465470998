import { Button } from 'components/catalyst/button'
import { Divider } from 'components/catalyst/divider'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar'
import { Text } from 'components/catalyst/text'
import { CodeEditor } from 'components/code-editor'
import { HardDriveUpload } from 'lucide-react'
import { SdkClientPlayground } from 'modules/api-playground/components/sdk-client-playground'
import { useDatasetCreateWizardStore } from 'modules/datasets/store/dataset-create.store'
import { useState } from 'react'

const CREATE_INDEX_BODY_OBJECT = `{
  "indexName": "<YOUR_INDEX_NAME>",
  "similarityFunction": "cosine",
  "cloud": "gcp",
  "region": "us-east1"
}`

const MOCK_VECTORS = `{
  "vectorId": "my-first-vector",
  "dimensions": [0.1, 0.2, 0.3, 0.4]
}
`

enum Tabs {
  FileUpload,
  Editor,
  Api,
}

export function InsertDataStep() {
  const { handleNext } = useDatasetCreateWizardStore()
  const [code, setCode] = useState(MOCK_VECTORS)

  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.FileUpload)
  const tabs = [
    { id: Tabs.FileUpload, label: 'Upload data manually' },
    { id: Tabs.Editor, label: 'Via the editor ' },
    { id: Tabs.Api, label: 'Through the API' },
  ]

  function onNextBtnClick() {
    handleNext()
  }

  const nextBtnText =
    selectedTab === Tabs.FileUpload
      ? 'Upload & Continue'
      : selectedTab === Tabs.Editor
        ? 'Insert & Continue'
        : 'Continue'

  return (
    <div className="!-mt-6 max-w-2xl">
      <Navbar>
        <NavbarSection className="w-full">
          {tabs?.map((tab, index) => (
            <NavbarItem
              onClick={() => setSelectedTab(tab.id)}
              current={tab.id === selectedTab}
              key={tab.label}
              href="#"
              className="flex-1"
            >
              <div className="flex flex-col">
                <Text className="mb-1.5 !text-xs">Option {index + 1}</Text>
                {tab.label}
              </div>
            </NavbarItem>
          ))}
        </NavbarSection>
        <NavbarSpacer />
      </Navbar>
      <Divider className="mb-6" soft />

      {selectedTab === Tabs.FileUpload && (
        <button className="relative flex w-full flex-col items-center justify-center space-y-2 rounded-lg border-2 border-dashed border-neutral-300 p-12 text-center hover:border-neutral-400 dark:border-neutral-700 dark:hover:border-neutral-500">
          <HardDriveUpload className="mb-2" size={24} />
          <span className="block text-sm font-medium text-neutral-700 dark:text-neutral-500">.json, .parquet</span>
          <span className="block text-sm font-medium text-neutral-700 dark:text-neutral-500">
            Click to upload data from your computer
          </span>
        </button>
      )}

      {selectedTab === Tabs.Editor && (
        <>
          <CodeEditor code={code} onChange={(value) => setCode(value)} />
        </>
      )}

      {selectedTab === Tabs.Api && (
        <div className="-mt-4">
          <SdkClientPlayground bodyObject={CREATE_INDEX_BODY_OBJECT} />
        </div>
      )}

      <Divider className="my-6" soft />
      <div className="flex justify-end gap-4">
        <Button onClick={handleNext}>{selectedTab === Tabs.Api ? 'Continue Next Step' : 'Skip'}</Button>
        {selectedTab !== Tabs.Api && (
          <Button onClick={onNextBtnClick} color="amber">
            {nextBtnText}
          </Button>
        )}
      </div>
    </div>
  )
}
