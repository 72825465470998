export const HttpRequestStringSnippet = {
  POST: `POST {{endpoint}} HTTP/1.1
  x-api-key: {{apiKey}}
  Accept: application/json
  Content-Type: application/json
  Host: {{host}}
  Content-Length: {{contentLength}}

  {{body}}
  `,

  GET: `GET {{endpoint}} HTTP/1.1
  x-api-key: {{apiKey}}
  Accept: application/json
  Host: {{host}}
  `,

  PUT: `PUT {{endpoint}} HTTP/1.1
  x-api-key: {{apiKey}}
  Accept: application/json
  Content-Type: application/json
  Host: {{host}}
  Content-Length: {{contentLength}}

  {{body}}
  `,

  DELETE: `DELETE {{endpoint}} HTTP/1.1
  x-api-key: {{apiKey}}
  Accept: application/json
  Host: {{host}}
  `,
}
