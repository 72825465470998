import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { CheckEmailFormSchema, LoginFormSchema } from '../schema'

import { checkEmailApi, loginApi, useAuthStore } from '@vectroid/shared/auth'
import { FacKeys, isFeatureEnabled } from '@vectroid/shared/config'
import { RouteNames } from '@vectroid/shared/const'
import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { Input } from 'components/catalyst/input'
import { Text } from 'components/catalyst/text'
import { Alert, AlertDescription } from 'components/ui/alert'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { ChevronLeft } from 'lucide-react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SocialLogins } from '../components/social-logins'

const checkEmailFormSchema = CheckEmailFormSchema
const loginFormSchema = LoginFormSchema

const loginFormFields = [
  { name: 'email', label: 'Email', type: 'email', disabled: true },
  { name: 'password', label: 'Password', type: 'password', autoFocus: true, 'data-testid': 'login-password' },
]

const checkEmailFormFields = [
  { name: 'email', label: 'Email', type: 'email', autoFocus: true, 'data-testid': 'login-email' },
]

function LoginPage() {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasPassword, setHasPassword] = useState(false)
  const [messageVariant, setMessageVariant] = useState<'default' | 'destructive'>('destructive')

  const loginAction = useAuthStore((state) => state.login)
  const navigate = useNavigate()

  const CheckEmailForm = useForm<z.infer<typeof checkEmailFormSchema>>({
    resolver: zodResolver(checkEmailFormSchema),
    defaultValues: {
      email: '',
    },
  })

  const LoginForm = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const form = hasPassword ? LoginForm : CheckEmailForm
  const formFields = hasPassword ? loginFormFields : checkEmailFormFields
  const formAction = hasPassword ? onLoginFormSubmit : onCheckEmailFormSubmit

  async function onCheckEmailFormSubmit(values: z.infer<typeof checkEmailFormSchema>) {
    try {
      setLoading(true)
      setErrorMessage('')

      const checkEmailResponse = await checkEmailApi(values)
      const { hasPassword, hasGoogleSSO } = checkEmailResponse.data
      if (hasGoogleSSO && !hasPassword) {
        setMessageVariant('default')
        setErrorMessage('You have signed up with Google SSO. Please continue with Google.')
        return
      }

      if (!hasGoogleSSO && !hasPassword) {
        setMessageVariant('default')
        setErrorMessage(
          'It looks like you haven\'t set up a password for your Vectroid account yet. <br/><span class="mt-2 block">A link to set your password has been sent to your email. Please check your inbox or spam folder to proceed.</span>'
        )
        return
      }

      setHasPassword(checkEmailResponse.data.hasPassword)
      LoginForm.setValue('email', values.email)
    } catch (e: any) {
      console.log(e)
      setMessageVariant('destructive')
      setErrorMessage(e?.response?.data?.message ?? GENERIC_ERROR_MESSAGE)
    } finally {
      setLoading(false)
    }
  }

  async function onLoginFormSubmit(values: z.infer<typeof loginFormSchema>) {
    try {
      setLoading(true)
      setErrorMessage('')

      const response = await loginApi(values)
      loginAction(response.data)
      navigate(RouteNames.Dashboard)
    } catch (e: any) {
      setMessageVariant('destructive')
      setErrorMessage(e?.response?.data?.message ?? GENERIC_ERROR_MESSAGE)
    } finally {
      setLoading(false)
    }
  }

  function onBackClick() {
    setHasPassword(false)
    setErrorMessage('')
  }

  function renderForm() {
    return formFields.map(({ name, label, ...formField }) => (
      <FormField
        key={name}
        name={name as any}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{label}</FormLabel>

            <FormControl>
              <Input disabled={loading} {...field} {...formField} />
            </FormControl>

            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />
    ))
  }

  return (
    <>
      {hasPassword ? (
        <button onClick={onBackClick} className="flex items-center py-2 text-sm text-zinc-400 hover:text-zinc-500">
          <ChevronLeft size={15} className="mr-3" />
          Back
        </button>
      ) : (
        <>
          <Heading level={1}>Sign In</Heading>

          {isFeatureEnabled(FacKeys.CreateAccount) && (
            <Text>
              New to Vectroid?{' '}
              <b className="font-normal text-sky-600 hover:text-sky-700 dark:text-sky-400 dark:hover:text-zinc-300">
                <Link to={RouteNames.Register}>Sign up for an account.</Link>
              </b>
            </Text>
          )}
        </>
      )}

      {/* @ts-ignore */}
      <Form {...form}>
        {/* @ts-ignore */}
        <form onSubmit={form.handleSubmit(formAction)} className="w-full space-y-6">
          {renderForm()}

          {errorMessage && (
            <Alert variant={messageVariant}>
              <AlertDescription dangerouslySetInnerHTML={{ __html: errorMessage }} />
            </Alert>
          )}

          <Text>
            <Link
              to={RouteNames.ForgotPassword}
              className="font-normal text-zinc-600 hover:text-zinc-700 dark:text-zinc-400 dark:hover:text-zinc-300"
            >
              Forgot your password?
            </Link>
          </Text>

          <Button type="submit" className="w-full" loading={loading} disabled={loading} data-testid="login-submit">
            Sign In
          </Button>

          <SocialLogins />
        </form>
      </Form>
    </>
  )
}

export default LoginPage
