import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { appConfig } from '@vectroid/shared/config'
import { useAuthStore } from '../auth'

interface ApiError {
  message: string
  status: number
}

interface ApiCallOptions {
  baseUrl?: string
  apiKey?: string
}

export const axiosClient = axios.create()

/**
 * @param url {String}
 * @param method {Method}
 * @param data T
 * @param options {ApiCallOptions} - Options for the API call
 * @returns {Promise<ApiResponse<T>>}
 */
export async function apiClient<T, K>(
  path: string,
  method: string,
  data?: T,
  options?: ApiCallOptions
): Promise<AxiosResponse<K>> {
  const config: AxiosRequestConfig<T> = {
    baseURL: `${options?.baseUrl || appConfig.get('API_BASE_URL')}${path}`,
    method,
    data,
    headers: {
      'Content-Type': 'application/json',
      ...(options?.apiKey && { 'x-api-key': options.apiKey }),
    },
  }

  try {
    return axiosClient(config)
  } catch (error: any) {
    const status = error?.response?.status || 500
    const message = error?.response?.data?.message || 'An unexpected error occurred'
    throw { message, status } as ApiError
  }
}

axiosClient.interceptors.request.use(
  (config) => {
    const token = useAuthStore?.getState()?.accessToken

    if (!config.headers['x-api-key'] && token) {
      config.headers = config.headers || {}
      config.headers.Authorization = `Bearer: ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
