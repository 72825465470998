import { formatVectorCountPrecise } from '@vectroid/shared/utils'
import { CommonTooltip } from 'components/common/tooltip/tooltip'
import { Skeleton } from 'components/ui/skeleton'
import { getDatasetURL } from 'modules/datasets/utils/get-dataset-url'
import { useEffect, useState } from 'react'
import { getDatasetSizeApi } from '../services'

type Props = {
  datasetName: string
  datasetUri: string
}

export function DatasetSizeProgress(props: Props) {
  const [datasetSize, setDatasetSize] = useState(0)
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let isMounted = true

    async function fetchDatasetSize() {
      try {
        setLoading(true)
        const response = await getDatasetSizeApi(getDatasetURL(props.datasetUri), props.datasetName)
        if (isMounted) {
          setDatasetSize(response.data.size)
          setLastUpdated(new Date())
        }
      } catch (error) {
        console.error('Error fetching index size:', error)
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    // Initial fetch
    fetchDatasetSize()

    // Set up polling every 10 seconds
    const interval = setInterval(fetchDatasetSize, 10000)

    // Cleanup on component unmount
    return () => {
      isMounted = false
      clearInterval(interval)
    }
  }, [])

  return (
    <CommonTooltip
      content={
        <div className="space-y-2 py-2">
          <p>
            Last update: <b>{lastUpdated ? lastUpdated.toLocaleTimeString() : 'N/A'}</b>
          </p>
        </div>
      }
    >
      <span>{loading ? <Skeleton className="mt-1 h-4 w-16" /> : formatVectorCountPrecise(datasetSize)}</span>
    </CommonTooltip>
  )
}
