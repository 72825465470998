import { getDatasetURL } from 'modules/datasets/utils/get-dataset-url'
import { useCollectionStore } from 'modules/indexes'

type Props = {
  baseUrl: string
  datasetUri?: string
}

export function ConsoleHeaderURI(props: Props) {
  const { activeCollection } = useCollectionStore()

  return (
    <span className="mr-0.5 pl-2 font-mono text-xs">
      {activeCollection.dataCellResource
        ? props.datasetUri
          ? getDatasetURL(props.datasetUri, false)
          : '[YOUR_DATASET_URI]'
        : props.baseUrl}
    </span>
  )
}
