import axios, { CancelTokenSource } from 'axios'
import { create } from 'zustand'
import { Dataset } from '..'
import { UploadStatus } from '../const/upload-status.enum'
import { startDatasetBulkIngestionApi } from '../services'
import { uploadChunk } from '../utils/chunk-uploader'
import { getDatasetURL } from '../utils/get-dataset-url'

interface UploadState {
  dataset: Dataset | null
  uploadId: string | null
  uploadProgress: number // 0..100
  uploadStatus: UploadStatus
  bytesUploaded: number
  totalBytes: number
  currentFile: File | null
  uploadSpeed: number // bytes per second
  cancelTokenSource: CancelTokenSource | null
  error: string | null

  startUpload: (signedUrl: string, file: File, isResumable: boolean) => Promise<void>
  setDataset: (dataset: Dataset) => void
  setUploadId: (uploadId: string) => void
  cancelUpload: () => void
  reset: () => void
}

export const useDatasetBulkUploadStore = create<UploadState>((set, get) => ({
  dataset: null,
  uploadId: null,
  uploadProgress: 0,
  uploadStatus: UploadStatus.Idle,
  bytesUploaded: 0,
  totalBytes: 0,
  currentFile: null,
  uploadSpeed: 0,
  cancelTokenSource: null,
  error: null,

  startUpload: async (signedUrl: string, file: File, isResumable: boolean) => {
    // Create a new cancel token for this upload
    const cancelSource = axios.CancelToken.source()
    set({
      currentFile: file,
      uploadStatus: UploadStatus.Uploading,
      uploadProgress: 0,
      bytesUploaded: 0,
      totalBytes: file.size,
      uploadSpeed: 0,
      cancelTokenSource: cancelSource,
      error: null,
    })

    const chunkSize = 50 * 1024 * 1024 // 50MB
    const totalChunks = Math.ceil(file.size / chunkSize)
    let cumulativeBytesUploaded = 0

    try {
      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const startByte = chunkIndex * chunkSize
        const endByte = Math.min(file.size, startByte + chunkSize)
        const headers: Record<string, string> = {}

        if (isResumable) {
          headers['Content-Range'] = `bytes ${startByte}-${endByte - 1}/${file.size}`
        } else {
          headers['Content-Type'] = 'application/octet-stream'
        }

        // Progress callback for the current chunk
        const createOnChunkProgress = (cumulativeBytes: number) => (loaded: number, speed?: number) => {
          const overallLoaded = cumulativeBytes + loaded
          const progress = Math.round((overallLoaded / file.size) * 100)
          set({
            uploadProgress: progress,
            bytesUploaded: overallLoaded,
            uploadSpeed: speed ?? get().uploadSpeed,
          })
        }

        const onChunkProgress = createOnChunkProgress(cumulativeBytesUploaded)

        // Upload the chunk with built-in retry logic
        await uploadChunk(signedUrl, file, startByte, endByte, chunkIndex, headers, onChunkProgress, cancelSource.token)

        cumulativeBytesUploaded += endByte - startByte
        set({
          uploadProgress: Math.round((cumulativeBytesUploaded / file.size) * 100),
          bytesUploaded: cumulativeBytesUploaded,
        })
      }

      // Once all chunks are uploaded, trigger dataset ingestion.
      const { dataset, uploadId } = get()
      if (dataset && uploadId) {
        try {
          await startDatasetBulkIngestionApi(getDatasetURL(dataset.uri), dataset.name, uploadId)
        } catch (apiError) {
          console.error('Dataset ingestion API error:', apiError)
          set({ uploadStatus: UploadStatus.Error, error: 'Dataset ingestion failed. Please try again.' })
          return
        }
      }

      // Mark upload as complete
      set({ uploadStatus: UploadStatus.Completed, uploadSpeed: 0 })
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Upload canceled by user.')
        set({ uploadStatus: UploadStatus.Canceled, uploadSpeed: 0, error: 'Upload canceled.' })
      } else {
        console.error('Error during upload:', error)
        set({
          uploadStatus: UploadStatus.Error,
          uploadSpeed: 0,
          error: 'An error occurred during upload. Please try again.',
        })
      }
    } finally {
      // Clean up the cancel token
      set({ cancelTokenSource: null })
    }
  },

  cancelUpload: () => {
    const { cancelTokenSource } = get()
    if (cancelTokenSource) {
      cancelTokenSource.cancel('User canceled upload.')
      set({
        uploadStatus: UploadStatus.Canceled,
        uploadSpeed: 0,
        error: 'Upload canceled.',
      })
    }
  },

  setDataset: (dataset: Dataset) => set({ dataset }),
  setUploadId: (uploadId: string) => set({ uploadId }),
  reset: () =>
    set({
      dataset: null,
      uploadId: null,
      uploadProgress: 0,
      uploadStatus: UploadStatus.Idle,
      bytesUploaded: 0,
      totalBytes: 0,
      currentFile: null,
      uploadSpeed: 0,
      cancelTokenSource: null,
      error: null,
    }),
}))
