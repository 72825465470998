import { useAuthStore, verifyAccountApi, VerifyAccountRequest } from '@vectroid/shared/auth'
import { RouteNames } from '@vectroid/shared/const'
import { Banner } from 'components/banner'
import { Button } from 'components/catalyst/button'
import { Divider } from 'components/catalyst/divider'
import { Heading } from 'components/catalyst/heading'
import { Text } from 'components/catalyst/text'
import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/ui/input-otp'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  requestId: string
}

function VerifyAccountForm(props: Props) {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const loginAction = useAuthStore((state) => state.login)
  const navigate = useNavigate()

  const [verificationCode, setVerificationCode] = useState('')

  async function handleAccountVerification(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (!props.requestId) {
      setErrorMessage('Account not found')
      setLoading(false)
      return
    }

    try {
      setLoading(true)
      const request: VerifyAccountRequest = {
        code: verificationCode,
        requestId: props.requestId,
      }
      const response = await verifyAccountApi(request)

      const { accessToken, refreshToken, account: accountResponse } = response.data

      loginAction({ accessToken, refreshToken, account: accountResponse })
      navigate(RouteNames.Dashboard)
    } catch (error: any) {
      console.error(error)
      setErrorMessage(error.message)
      setLoading(false)
    }
  }

  function backToLogin() {
    navigate(RouteNames.Logout)
  }

  return (
    <>
      <Heading level={1} className="w-full text-center">
        Verify Your Email
        <Text className="font-normal">Please enter the code you received in your email.</Text>
      </Heading>

      <form className="!mt-0 w-full space-y-6" onSubmit={handleAccountVerification}>
        <div className="flex w-full items-center justify-center">
          <InputOTP
            maxLength={6}
            className="justify-center"
            onChange={setVerificationCode}
            data-testid="verify-account-code"
          >
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
        </div>

        <div className="mx-auto flex flex-col items-center justify-center">
          <Button className="mb-5" color="amber" type="submit" loading={loading} data-testid="verify-account-submit">
            Verify email
          </Button>
          <Divider soft />
          <Text className="mb-3 mt-3 text-center text-xs">Didn't receive the code?</Text>
          <div className="flex items-center justify-center">
            <Button className="font-normal" plain>
              Resend the code
            </Button>
            <Text className="text-center text-xs">or</Text>
            <Button className="font-normal" plain href={RouteNames.Logout}>
              Logout
            </Button>
          </div>
        </div>
      </form>

      {errorMessage && (
        <div className="w-full">
          <Banner title="Failed to verify your account" message={errorMessage} type="error" />
          <Button onClick={backToLogin}>Back to Login</Button>
        </div>
      )}
    </>
  )
}

export default VerifyAccountForm
