export const OCamlCohttpSnippet = {
  POST: `open Cohttp_lwt_unix
open Cohttp
open Lwt

let uri = Uri.of_string "{{url}}" in
let headers = Header.add_list (Header.init ()) [
  ("x-api-key", "{{apiKey}}");
  ("Accept", "application/json");
  ("Content-Type", "application/json");
] in
let body = Cohttp_lwt.Body.of_string "{{body}}" in

Client.post ~headers ~body uri
>>= fun (res, body_stream) ->
  (* Handle response *)
  body_stream |> Cohttp_lwt.Body.to_string >|= print_endline`,

  GET: `open Cohttp_lwt_unix
open Cohttp
open Lwt

let uri = Uri.of_string "{{url}}" in
let headers = Header.add_list (Header.init ()) [
  ("x-api-key", "{{apiKey}}");
  ("Accept", "application/json");
] in

Client.get ~headers uri
>>= fun (res, body_stream) ->
  (* Handle response *)
  body_stream |> Cohttp_lwt.Body.to_string >|= print_endline`,

  PUT: `open Cohttp_lwt_unix
open Cohttp
open Lwt

let uri = Uri.of_string "{{url}}" in
let headers = Header.add_list (Header.init ()) [
  ("x-api-key", "{{apiKey}}");
  ("Accept", "application/json");
  ("Content-Type", "application/json");
] in
let body = Cohttp_lwt.Body.of_string "{{body}}" in

Client.put ~headers ~body uri
>>= fun (res, body_stream) ->
  (* Handle response *)
  body_stream |> Cohttp_lwt.Body.to_string >|= print_endline`,

  DELETE: `open Cohttp_lwt_unix
open Cohttp
open Lwt

let uri = Uri.of_string "{{url}}" in
let headers = Header.add_list (Header.init ()) [
  ("x-api-key", "{{apiKey}}");
  ("Accept", "application/json");
] in

Client.delete ~headers uri
>>= fun (res, body_stream) ->
  (* Handle response *)
  body_stream |> Cohttp_lwt.Body.to_string >|= print_endline`,
}
