import { RouteNames } from '@vectroid/shared/const'
import { ApiDocsButton } from 'components/api-docs-button'
import { DropdownButton } from 'components/catalyst/dropdown'
import { Heading } from 'components/catalyst/heading'
import { CommonDropdown } from 'components/common'
import { DashboardSkeleton } from 'components/skeletons'
import { ChevronDown, PlusIcon } from 'lucide-react'
import { useEffect, useRef } from 'react'
import { DashboardEmptyState } from '../components/dashboard-empty-state'
import { DashboadRecentlyViewed } from '../components/dashboard-recently-viewed'
import { useDashboard } from '../store'

export function DashboardPage() {
  const { datasets, indexes, loading, fetchResources } = useDashboard()
  const docsBtnRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    fetchResources()
  }, [])

  function renderDashboard() {
    if (loading) {
      return <DashboardSkeleton />
    }

    if (!datasets.length && !indexes.length) {
      return <DashboardEmptyState />
    }

    return <DashboadRecentlyViewed datasets={datasets} indexes={indexes} />
  }

  return (
    <>
      <Heading
        level={2}
        className="sm:text-lg/8"
        pageHeading
        data-testid="dashboard-heading"
        actions={
          <>
            <ApiDocsButton path="/quickstart/#2-create-a-dataset" ref={docsBtnRef} />
            <CommonDropdown
              className="!p-0"
              anchor="bottom start"
              menuItems={[
                {
                  key: 'dataset',
                  label: 'Dataset',
                  href: `${RouteNames.Datasets}?create=true`,
                },
                {
                  key: 'index',
                  label: 'Index',
                  href: `${RouteNames.Indexes}?create=true`,
                },
              ]}
            >
              <DropdownButton color="amber">
                <PlusIcon className="size-4" strokeWidth={3} /> Create New <ChevronDown className="size-6" />
              </DropdownButton>
            </CommonDropdown>
          </>
        }
      >
        Dashboard
      </Heading>

      {renderDashboard()}
    </>
  )
}
