import axios from 'axios'
import { CloudProvider, ResumableSessionInfo } from '..'

/**
 * Initiates a resumable upload session with provided cloud provider.
 */
export async function initiateResumableSession(url: string, sessionInfo: ResumableSessionInfo): Promise<string> {
  switch (sessionInfo.cloud) {
    case CloudProvider.GCP:
      return initiateResumableSessionForGCP(url, sessionInfo)

    default:
      throw new Error('Unsupported provider.')
  }
}

/**
 * Initiates a resumable upload session with GCP using the signed URL.
 *
 * @param url {string}
 * @param sessionInfo {ResumableSessionInfo}
 * @returns {Promise<string>}
 */
export async function initiateResumableSessionForGCP(url: string, sessionInfo: ResumableSessionInfo): Promise<string> {
  try {
    const response = await axios({
      method: sessionInfo.method,
      url,
      headers: {
        'x-goog-resumable': 'start',
      },
    })

    const sessionUrl = response.headers['location']
    if (!sessionUrl) {
      throw new Error('No session URL returned from initiation.')
    }
    return sessionUrl
  } catch (error) {
    throw error
  }
}
