export const CurlSnippet = {
  POST: `CURL *hnd = curl_easy_init();

  curl_easy_setopt(hnd, CURLOPT_CUSTOMREQUEST, "POST");
  curl_easy_setopt(hnd, CURLOPT_URL, "{{url}}");

  struct curl_slist *headers = NULL;
  headers = curl_slist_append(headers, "x-api-key: {{apiKey}}");
  headers = curl_slist_append(headers, "Accept: application/json");
  headers = curl_slist_append(headers, "Content-Type: application/json");
  curl_easy_setopt(hnd, CURLOPT_HTTPHEADER, headers);

  curl_easy_setopt(hnd, CURLOPT_POSTFIELDS, "{{body}}");

  CURLcode ret = curl_easy_perform(hnd);
  curl_easy_cleanup(hnd);`,

  GET: `CURL *hnd = curl_easy_init();

  curl_easy_setopt(hnd, CURLOPT_CUSTOMREQUEST, "GET");
  curl_easy_setopt(hnd, CURLOPT_URL, "{{url}}");

  struct curl_slist *headers = NULL;
  headers = curl_slist_append(headers, "x-api-key: {{apiKey}}");
  headers = curl_slist_append(headers, "Accept: application/json");
  curl_easy_setopt(hnd, CURLOPT_HTTPHEADER, headers);

  // GET requests do not send a body
  curl_easy_setopt(hnd, CURLOPT_POSTFIELDS, NULL);

  CURLcode ret = curl_easy_perform(hnd);
  curl_easy_cleanup(hnd);`,

  PUT: `CURL *hnd = curl_easy_init();

  curl_easy_setopt(hnd, CURLOPT_CUSTOMREQUEST, "PUT");
  curl_easy_setopt(hnd, CURLOPT_URL, "{{url}}");

  struct curl_slist *headers = NULL;
  headers = curl_slist_append(headers, "x-api-key: {{apiKey}}");
  headers = curl_slist_append(headers, "Accept: application/json");
  headers = curl_slist_append(headers, "Content-Type: application/json");
  curl_easy_setopt(hnd, CURLOPT_HTTPHEADER, headers);

  curl_easy_setopt(hnd, CURLOPT_POSTFIELDS, "{{body}}");

  CURLcode ret = curl_easy_perform(hnd);
  curl_easy_cleanup(hnd);`,

  DELETE: `CURL *hnd = curl_easy_init();

  curl_easy_setopt(hnd, CURLOPT_CUSTOMREQUEST, "DELETE");
  curl_easy_setopt(hnd, CURLOPT_URL, "{{url}}");

  struct curl_slist *headers = NULL;
  headers = curl_slist_append(headers, "x-api-key: {{apiKey}}");
  headers = curl_slist_append(headers, "Accept: application/json");
  curl_easy_setopt(hnd, CURLOPT_HTTPHEADER, headers);

  // DELETE requests usually do not have a body
  curl_easy_setopt(hnd, CURLOPT_POSTFIELDS, NULL);

  CURLcode ret = curl_easy_perform(hnd);
  curl_easy_cleanup(hnd);`,
}
