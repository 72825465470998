import { Card } from 'components/catalyst/card'
import { Text } from 'components/catalyst/text'
import { ColumnDefinition, CommonTable } from 'components/common'
import { RouteNames } from '@vectroid/shared/const'
import { Index } from 'modules/indexes'
import { useDatasetDetailStore } from '../store/datase-detail.store'
import { IndexBadgeButton } from 'modules/indexes/components/index-badge-button'
import { IndexSizeProgress } from 'modules/indexes/components/index-size-progress'

export function DatasetIndexesTable() {
  const datasetDetailStore = useDatasetDetailStore()
  const { datasetIndexes, dataset } = datasetDetailStore

  const columns: ColumnDefinition<Index>[] = [
    { key: 'id', header: 'ID' },
    {
      key: 'name',
      header: 'Index Name',
      cell: (row) => {
        return <IndexBadgeButton index={row} />
      },
    },
    {
      key: 'indexingProgress',
      header: 'Indexing Progress',
      cell: (row) => <IndexSizeProgress datasetUri={dataset?.uri!} indexName={row.name} />,
    },
    { key: 'similarityFunction', header: 'Similarity Function' },
  ]

  return (
    <Card heading="Indexes in this Dataset">
      <CommonTable<Index>
        columns={columns}
        data={datasetIndexes ?? []}
        setRowLink={(row) => RouteNames.IndexDetail.get(row.name)}
        emptyState={<Text>No indexes was found with this dataset.</Text>}
      />
    </Card>
  )
}
