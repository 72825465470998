import { apiClient } from '@vectroid/shared/api'

/**
 * Create a dataset
 *
 * @param {SendFeedbackRequest} values
 * @returns {Promise<SendFeedbackResponse>}
 */
export function sendFeedbackApi(values: SendFeedbackRequest) {
  return apiClient<SendFeedbackRequest, SendFeedbackResponse>('/account/feedback', 'POST', values)
}

export interface SendFeedbackRequest {
  email: string
  message: string
}

export interface SendFeedbackResponse {
  success: boolean
}
