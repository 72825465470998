import { z } from 'zod'

import { setupNewPassword, useAuthStore } from '@vectroid/shared/auth'
import { RouteNames } from '@vectroid/shared/const'
import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { useNotificationStore } from 'components/common'
import { Form } from 'components/common/form/form'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ResetPasswordFormSchema } from '../schema/reset-password.schema'

import { Divider } from 'components/catalyst/divider'
import { Text } from 'components/catalyst/text'

const formSchema = ResetPasswordFormSchema

enum FieldNames {
  Code = 'code',
  Password = 'password',
  PasswordConfirmation = 'passwordConfirmation',
}

type FormSchema = z.infer<typeof formSchema>

const formFields = [
  { name: FieldNames.Code, label: 'Code', 'data-testid': 'forgot-password-code' },
  { name: FieldNames.Password, label: 'Password', type: 'password', 'data-testid': 'forgot-password-password' },
  {
    name: FieldNames.PasswordConfirmation,
    label: 'Confirm Password',
    type: 'password',
    'data-testid': 'forgot-password-password-confirm',
  },
]

type Props = {
  requestId: string
  resendCode: () => void
}

function ResetPasswordForm(props: Props) {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()

  const loginAction = useAuthStore((state) => state.login)
  const navigate = useNavigate()

  const defaultValues: FormSchema = {
    code: '',
    password: '',
    passwordConfirmation: '',
  }

  async function onSubmit(values: FormSchema) {
    try {
      setLoading(true)

      if (values.password !== values.passwordConfirmation) {
        setMessage('Passwords do not match', { type: 'error' })
        return
      }

      const response = await setupNewPassword({
        password: values.password,
        code: values.code,
        requestId: props.requestId,
      })
      loginAction(response.data)
      navigate(RouteNames.Dashboard)
    } catch (e: any) {
      console.log(e)
      setMessage(e?.message ?? GENERIC_ERROR_MESSAGE, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Heading
        level={1}
        description="We have sent you an email with a verification code. Please enter the code below and reset your password."
      >
        Reset Password
      </Heading>

      <Form
        formFields={formFields}
        formSchema={formSchema}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        loading={loading}
        actionBtnText="Reset Password & Login"
        inlineErrorMessages
        actionBtnProps={{ color: 'zinc', className: 'w-full', 'data-testid': 'reset-password-submit' }}
      />

      <div className="mx-auto flex w-full flex-col items-center justify-center">
        <Divider soft />
        <Text className="mb-1 mt-3 text-center text-xs">Didn't receive the code?</Text>
        <div className="flex items-center justify-center">
          <Button className="font-normal" plain onClick={props.resendCode}>
            Resend the Code
          </Button>
        </div>
      </div>
    </>
  )
}

export default ResetPasswordForm
