import { Text } from 'components/catalyst/text'
import { CheckCircle, Loader2, X } from 'lucide-react'
import { UploadStatus } from 'modules/datasets/const/upload-status.enum'
import React from 'react'

interface UploadProgressHeaderProps {
  isMinimized: boolean
  uploadStatus: UploadStatus
  currentFile: File
  dataset: { name: string } | null
  timeLeft: string | null
  error?: string | null
}

export const UploadProgressHeader: React.FC<UploadProgressHeaderProps> = ({
  isMinimized,
  uploadStatus,
  currentFile,
  dataset,
  timeLeft,
  error,
}) => {
  if (uploadStatus === UploadStatus.Error) {
    return (
      <div className="flex flex-col">
        <div className="flex items-center space-x-2">
          <X className="h-5 w-5 text-red-600" />
          <Text className="font-semibold dark:text-zinc-100">Upload Failed</Text>
        </div>
        {error && <Text className="text-sm text-red-600 dark:text-red-400">{error}</Text>}
      </div>
    )
  }

  if (uploadStatus === UploadStatus.Canceled) {
    return (
      <div className="flex flex-col">
        <div className="flex items-center space-x-2">
          <X className="h-5 w-5 text-yellow-600" />
          <Text className="font-semibold dark:text-zinc-100">Upload Canceled</Text>
        </div>
      </div>
    )
  }

  if (isMinimized) {
    return (
      <div className="flex flex-col">
        <Text className="flex-1 overflow-hidden text-ellipsis font-medium text-gray-900 dark:text-zinc-100">
          {currentFile.name}
        </Text>
        {dataset && <Text>{dataset.name}</Text>}
      </div>
    )
  }
  if (uploadStatus === UploadStatus.Completed) {
    return (
      <div className="flex items-center space-x-2">
        <CheckCircle className="h-5 w-5 text-green-600" />
        <Text className="font-semibold dark:text-zinc-100">Upload Complete</Text>
      </div>
    )
  }
  return (
    <div className="flex items-center space-x-2">
      <Loader2 className="h-5 w-5 animate-spin text-emerald-600" />
      <Text className="font-semibold dark:text-zinc-100">Uploading 1 item</Text>
      {timeLeft && <Text className="text-sm text-zinc-500 dark:text-zinc-300">• {timeLeft} left</Text>}
    </div>
  )
}
