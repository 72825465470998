import { cn } from '@vectroid/shared/utils'

export function Divider({
  soft = false,
  className,
  vertical = false, // Added vertical prop
  ...props
}: { soft?: boolean; vertical?: boolean } & React.ComponentPropsWithoutRef<'hr'>) {
  return (
    <hr
      role="presentation"
      {...props}
      className={cn(
        className,
        vertical ? 'h-full border-l' : 'w-full border-t', // Adjusted for vertical
        soft && (vertical ? 'border-zinc-950/5 dark:border-white/5' : 'border-zinc-950/5 dark:border-white/5'),
        !soft && (vertical ? 'border-zinc-950/10 dark:border-white/15' : 'border-zinc-950/10 dark:border-white/15')
      )}
    />
  )
}
