import { RouteNames } from '@vectroid/shared/const'
import { Badge } from 'components/catalyst/badge'
import { Heading } from 'components/catalyst/heading'
import { Text } from 'components/catalyst/text'
import { ArrowRightIcon, FileSearch2, FolderOpenDot } from 'lucide-react'
import { Dataset } from 'modules/datasets'
import { getDatasetStatusBadgeColor } from 'modules/datasets/utils'
import { Index } from 'modules/indexes'
import { Link } from 'react-router-dom'
import { useTrackRecentResources } from '../store'
import { DashboardRecentItem } from './dashboard-recent-item'

type Props = {
  datasets: Dataset[]
  indexes: Index[]
}

export function DashboadRecentlyViewed({ datasets, indexes }: Props) {
  const { recentDatasetNames, recentIndexNames } = useTrackRecentResources()

  // Build a list of recent datasets based on local storage.
  const filteredDatasets = recentDatasetNames
    .map((name) => datasets.find((dataset) => dataset.name === name))
    .filter((dataset) => dataset !== undefined) as Dataset[]

  // If no recent datasets exist, fall back to a subset of available datasets (e.g. first 3)
  const datasetsToShow = filteredDatasets.length > 0 ? filteredDatasets : datasets.slice(0, 4)

  // Build a list of recent indexes based on local storage.
  const filteredIndexes = recentIndexNames
    .map((name) => indexes.find((index) => index.name === name))
    .filter((index) => index !== undefined) as Index[]

  // If no recent indexes exist, fall back to a subset of available indexes (e.g. first 3)
  const indexesToShow = filteredIndexes.length > 0 ? filteredIndexes : indexes.slice(0, 4)

  // Change the section titles based on whether there are recent items or not.
  const datasetTitle = filteredDatasets.length > 0 ? 'Recently Viewed Datasets' : 'Available Datasets'
  const indexTitle = filteredIndexes.length > 0 ? 'Recently Viewed Indexes' : 'Available Indexes'

  return (
    <div className="mt-3 max-w-5xl space-y-8">
      <section>
        <Heading className="flex w-full !items-center !text-lg">
          <span className="flex-1 text-base">{datasetTitle}</span>
          <Link to={RouteNames.Datasets} className="mt-1 text-xs font-normal opacity-70 hover:opacity-100">
            <Text className="flex items-center space-x-2">
              <span>View all</span>
              <ArrowRightIcon size={16} />
            </Text>
          </Link>
        </Heading>
        <div className="grid grid-cols-12 gap-4">
          {datasetsToShow.map((dataset) => (
            <DashboardRecentItem
              label={dataset.name}
              key={dataset.id}
              href={RouteNames.DatasetDetail.get(dataset.name)}
              headerColor="dark:bg-amber-900/10 bg-amber-50"
              icon={
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-amber-200 dark:bg-amber-800/20">
                  <FolderOpenDot size={20} />
                </div>
              }
            >
              <Badge className="!text-xs" color={getDatasetStatusBadgeColor(dataset.status)}>
                {dataset.status}
              </Badge>
            </DashboardRecentItem>
          ))}
        </div>
      </section>

      <section>
        <Heading className="flex w-full !items-center !text-lg">
          <span className="flex-1 text-base">{indexTitle}</span>
          <Link to={RouteNames.Indexes} className="mt-1 text-xs font-normal opacity-70 hover:opacity-100">
            <Text className="flex items-center space-x-2">
              <span>View all</span>
              <ArrowRightIcon size={16} />
            </Text>
          </Link>
        </Heading>
        <div className="grid grid-cols-12 gap-4">
          {indexesToShow.map((index) => (
            <DashboardRecentItem
              label={index.name}
              key={index.id}
              href={RouteNames.IndexDetail.get(index.name)}
              headerColor="dark:bg-sky-900/10 bg-sky-50"
              icon={
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-sky-200 dark:bg-sky-800/30">
                  <FileSearch2 size={20} />
                </div>
              }
            >
              <Badge className="!text-xs">{index.similarityFunction}</Badge>
            </DashboardRecentItem>
          ))}
        </div>
      </section>
    </div>
  )
}
