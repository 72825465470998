export const RHttrSnippet = {
  POST: `library(httr)

  url <- "{{url}}"

  payload <- "{{body}}"

  response <- POST(url, body = payload, add_headers('x-api-key' = '{{apiKey}}'), content_type("application/json"), accept("application/json"))

  content(response, "text")`,

  GET: `library(httr)

  url <- "{{url}}"

  response <- GET(url, add_headers('x-api-key' = '{{apiKey}}'), accept("application/json"))

  content(response, "text")`,

  PUT: `library(httr)

  url <- "{{url}}"

  payload <- "{{body}}"

  response <- PUT(url, body = payload, add_headers('x-api-key' = '{{apiKey}}'), content_type("application/json"), accept("application/json"))

  content(response, "text")`,

  DELETE: `library(httr)

  url <- "{{url}}"

  response <- DELETE(url, add_headers('x-api-key' = '{{apiKey}}'), accept("application/json"))

  content(response, "text")`,
}
