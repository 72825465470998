import { RouteNames } from '@vectroid/shared/const'
import { Badge } from 'components/catalyst/badge'
import { ColumnDefinition, CommonTable } from 'components/common'
import { useSearchParamFilteredArray } from 'hooks/use-search-query-param'
import { useDatasetStore } from '../store/dataset.store'
import { Dataset } from '../types'
import { getDatasetStatusBadgeColor } from '../utils'
import { DatasetBadgeButton } from './dataset-badge-button'

export function DatasetStackedLayout() {
  const { datasets, loading } = useDatasetStore()

  const filteredDatasets = useSearchParamFilteredArray(datasets, { searchKeys: ['name'] })

  const columns: ColumnDefinition<Dataset>[] = [
    { key: 'id', header: 'ID' },
    { key: 'name', header: 'Name', cell: (row) => <DatasetBadgeButton dataset={row} /> },
    { key: 'dimensions', header: 'Dimensions' },
    {
      key: 'status',
      header: 'Status',
      cell: (row) => <Badge color={getDatasetStatusBadgeColor(row.status)}>{row.status}</Badge>,
    },
  ]

  return (
    <CommonTable<Dataset>
      columns={columns}
      data={filteredDatasets}
      loading={loading}
      setRowLink={(row) => RouteNames.DatasetDetail.get(row.name)}
    />
  )
}
