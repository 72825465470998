import { formatTimeRemaining } from 'modules/datasets/utils'
import { useEffect, useState } from 'react'

export function useTimeLeft(currentFile: File | null, uploadSpeed: number, bytesUploaded: number): string | null {
  const [timeLeft, setTimeLeft] = useState<string | null>(null)

  useEffect(() => {
    if (!currentFile || uploadSpeed <= 0) {
      setTimeLeft(null)
      return
    }
    const remainingBytes = currentFile.size - bytesUploaded
    if (remainingBytes <= 0) {
      setTimeLeft(null)
      return
    }
    const secondsLeft = remainingBytes / uploadSpeed
    setTimeLeft(formatTimeRemaining(secondsLeft))
  }, [currentFile, uploadSpeed, bytesUploaded])

  return timeLeft
}
