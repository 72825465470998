export const KotlinOkHttpSnippet = {
  POST: `val client = OkHttpClient()

val mediaType = MediaType.parse("application/json")
val body = RequestBody.create(mediaType, "{{body}}")
val request = Request.Builder()
  .url("{{url}}")
  .post(body)
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .addHeader("Content-Type", "application/json")
  .build()

val response = client.newCall(request).execute()`,

  GET: `val client = OkHttpClient()

val request = Request.Builder()
  .url("{{url}}")
  .get()
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .build()

val response = client.newCall(request).execute()`,

  PUT: `val client = OkHttpClient()

val mediaType = MediaType.parse("application/json")
val body = RequestBody.create(mediaType, "{{body}}")
val request = Request.Builder()
  .url("{{url}}")
  .put(body)
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .addHeader("Content-Type", "application/json")
  .build()

val response = client.newCall(request).execute()`,

  DELETE: `val client = OkHttpClient()

val request = Request.Builder()
  .url("{{url}}")
  .delete()
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .build()

val response = client.newCall(request).execute()`,
}
