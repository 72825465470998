import { Dataset, DatasetSelectComboBox } from 'modules/datasets'
import { Field, FieldTypes } from '../types'

type Props = {
  formField: Field & { fieldType: FieldTypes.DatasetSelect }
  field: any
  loading?: boolean
}

export function DatasetSelectField({ field }: Props) {
  return (
    <DatasetSelectComboBox
      value={field.value}
      onChange={(dataset: Dataset | null) => {
        field.onChange(dataset?.name ?? '')
      }}
      showDatasetTypeSelector={true}
    />
  )
}
