import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export type TabItem = {
  label: string
  href: string
  current?: boolean
  isPageTab?: boolean
  enabled?: boolean
}

export function useTabs(tabs: TabItem[]) {
  const [searchParams] = useSearchParams()
  const queryParamTab = searchParams.get('tab')

  const initialTab = searchParams.get('tab') || tabs[0].href
  const [currentTab, setCurrentTab] = useState<string>(initialTab)

  const mappedTabs = tabs.map((tab) => ({
    ...tab,
    current: tab.href === currentTab,
  }))

  function _setCurrentTab(tab: string) {
    searchParams.set('tab', tab)
  }

  useEffect(() => {
    if (queryParamTab) {
      setCurrentTab(queryParamTab)
    }
  }, [queryParamTab])

  return { tabs: mappedTabs, currentTab, setCurrentTab: _setCurrentTab }
}
