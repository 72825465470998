import { useEffect, useRef } from 'react'
import { AccessToken, refreshTokenApi, useAuthStore } from '../../auth'
import { parseJwt } from '../../utils'

export function useRefreshToken() {
  const accessToken = useAuthStore((state) => state.accessToken)
  const refreshToken = useAuthStore((state) => state.refreshToken)
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const setAccessToken = useAuthStore((state) => state.setAccessToken)
  const setRefreshToken = useAuthStore((state) => state.setRefreshToken)

  // Ref to store the scheduled refresh job's timeout id
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  async function fetchAccessTokens() {
    try {
      if (refreshToken) {
        const { accessToken: newAccessToken, refreshToken: newRefreshToken } = (await refreshTokenApi({ refreshToken }))
          .data
        setAccessToken(newAccessToken)
        setRefreshToken(newRefreshToken)
      }
    } catch (e: unknown) {
      console.log('Error fetching access tokens:', e)
    }
  }

  async function scheduleRefreshTokens() {
    try {
      const parsedToken = parseJwt<AccessToken>(accessToken)
      const now = Date.now()
      // Calculate delay (in ms) for scheduling: token expiry minus current time minus 10 seconds.
      const delay = parsedToken!.exp - now - 10000

      if (delay <= 0) {
        await fetchAccessTokens()
        return
      }

      // Check if a refresh job is already scheduled
      if (refreshTimeoutRef.current) {
        return
      }

      refreshTimeoutRef.current = setTimeout(async () => {
        await fetchAccessTokens()
        // Clear the timeout ref once executed
        refreshTimeoutRef.current = null
      }, delay)
    } catch (e) {
      console.log('Error scheduling refresh token:', e)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      scheduleRefreshTokens()
    }
    // Cleanup the timeout on unmount or when dependencies change
    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current)
        refreshTimeoutRef.current = null
      }
    }
  }, [isAuthenticated, accessToken])
}
