export const ClojureSnippet = {
  POST: `
(require '[clj-http.client :as client])

;; Sends a POST request with the given body and headers
(client/post "{{url}}"
  {:headers {:x-api-key "{{apiKey}}"}
   :body "{{body}}"
   :accept :json})
`,

  GET: `
(require '[clj-http.client :as client])

;; Sends a GET request with headers only, as GET does not include a body
(client/get "{{url}}"
  {:headers {:x-api-key "{{apiKey}}"}
   :accept :json})
`,

  PUT: `
(require '[clj-http.client :as client])

;; Sends a PUT request with a JSON body and headers
(client/put "{{url}}"
  {:headers {:x-api-key "{{apiKey}}"}
   :body "{{body}}"
   :accept :json})
`,

  DELETE: `
(require '[clj-http.client :as client])

;; Sends a DELETE request with headers; body is optional
(client/delete "{{url}}"
  {:headers {:x-api-key "{{apiKey}}"}
   :accept :json})
`,
}
