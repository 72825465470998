'use client'

import { useEffect, useState } from 'react'
import { VectroidLogo } from '../../components'
import LocalFeatureAccessControlConfig from '../feature-access-control.json'
import { localAppConfig, setAppConfig } from '../lib/app-config'
import { createFeatureAccessControl } from '../lib/fac-control'

type Props = {
  children: React.ReactNode
}

export function AppConfigProvider({ children }: Props) {
  const [loaded, setLoaded] = useState(false)

  const loadRemoteConfigs = async () => {
    try {
      const configPath = window.location.origin + '/console/config'
      const [facResult, appConfigResult] = await Promise.allSettled([
        fetch(`${configPath}/fac.json`).then((res) => res.json()),
        fetch(`${configPath}/app-config.json`).then((res) => res.json()),
      ])

      if (facResult.status === 'fulfilled') {
        createFeatureAccessControl(facResult.value)
      } else {
        console.error('Failed to load remote FAC config:', facResult.reason)
      }

      if (appConfigResult.status === 'fulfilled') {
        setAppConfig(appConfigResult.value)
      } else {
        console.error('Failed to load remote App config:', appConfigResult.reason)
      }

      setLoaded(true)
    } catch (error) {
      console.error('Unexpected error loading configs:', error)
    }
  }

  const initFeatureAccessControl = async () => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Initializing app config with default app config...')
      createFeatureAccessControl(LocalFeatureAccessControlConfig)
      setAppConfig(localAppConfig)
      setLoaded(true)
      return
    }

    loadRemoteConfigs()
  }

  useEffect(() => {
    initFeatureAccessControl()
  }, [])

  if (!loaded) {
    return (
      <div
        className="flex w-full flex-col items-center justify-center bg-zinc-100 dark:bg-zinc-950"
        style={{ height: '100vh' }}
      >
        <div className="h-10 w-10 animate-spin rounded-full border-2 !border-red-200 border-x-transparent border-b-transparent border-t-current" />
        <VectroidLogo className="mt-8 scale-150" style={{ transform: 'scale(1.45)' }} />
      </div>
    )
  }

  return <>{children}</>
}
