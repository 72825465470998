import { RouteNames } from '@vectroid/shared/const'
import { BadgeButton } from 'components/catalyst/badge'
import { CommonTooltip } from 'components/common/tooltip/tooltip'
import { FileSearch } from 'lucide-react'
import { Index } from '../types'

type Props = {
  index: Index
  className?: string
}

export function IndexBadgeButton({ index, className }: Props) {
  return (
    <CommonTooltip content={`Status: ${index.status}`} enabled={false}>
      <BadgeButton color={'sky'} href={RouteNames.IndexDetail.get(index.name)} className={className}>
        <FileSearch size={16} />
        {index.name}
      </BadgeButton>
    </CommonTooltip>
  )
}
