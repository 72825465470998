export const PowershellInvokeRestMethodSnippet = {
  POST: `$headers=@{}
  $headers.Add("x-api-key", "{{apiKey}}")
  $headers.Add("Accept", "application/json")
  $headers.Add("Content-Type", "application/json")
  $response = Invoke-RestMethod -Uri '{{url}}' -Method Post -Headers $headers -ContentType 'application/json' -Body '{{body}}'`,

  GET: `$headers=@{}
  $headers.Add("x-api-key", "{{apiKey}}")
  $headers.Add("Accept", "application/json")
  $response = Invoke-RestMethod -Uri '{{url}}' -Method Get -Headers $headers`,

  PUT: `$headers=@{}
  $headers.Add("x-api-key", "{{apiKey}}")
  $headers.Add("Accept", "application/json")
  $headers.Add("Content-Type", "application/json")
  $response = Invoke-RestMethod -Uri '{{url}}' -Method Put -Headers $headers -ContentType 'application/json' -Body '{{body}}'`,

  DELETE: `$headers=@{}
  $headers.Add("x-api-key", "{{apiKey}}")
  $headers.Add("Accept", "application/json")
  $response = Invoke-RestMethod -Uri '{{url}}' -Method Delete -Headers $headers`,
}

export const PowershellInvokeWebRequestSnippet = {
  POST: `$headers=@{}
  $headers.Add("x-api-key", "{{apiKey}}")
  $headers.Add("Accept", "application/json")
  $headers.Add("Content-Type", "application/json")
  $response = Invoke-WebRequest -Uri '{{url}}' -Method Post -Headers $headers -ContentType 'application/json' -Body '{{body}}'`,

  GET: `$headers=@{}
  $headers.Add("x-api-key", "{{apiKey}}")
  $headers.Add("Accept", "application/json")
  $response = Invoke-WebRequest -Uri '{{url}}' -Method Get -Headers $headers`,

  PUT: `$headers=@{}
  $headers.Add("x-api-key", "{{apiKey}}")
  $headers.Add("Accept", "application/json")
  $headers.Add("Content-Type", "application/json")
  $response = Invoke-WebRequest -Uri '{{url}}' -Method Put -Headers $headers -ContentType 'application/json' -Body '{{body}}'`,

  DELETE: `$headers=@{}
  $headers.Add("x-api-key", "{{apiKey}}")
  $headers.Add("Accept", "application/json")
  $response = Invoke-WebRequest -Uri '{{url}}' -Method Delete -Headers $headers`,
}
