import { z } from 'zod'

export const CreateIndexFormSchema = z.object({
  datasetName: z.string().refine((val) => val.length > 0, { message: 'Select a dataset or create a new one.' }),
  indexName: z.string().min(2).max(50),
  similarity: z.string().min(2).max(50),
  hnswConnections: z.number().min(16).max(64),
  hnswBeamWidth: z.number().min(100).max(512),
  quantizationType: z.string().min(2).max(50),
  quantizationBits: z.number().min(4).max(32).optional(),
})
