import { ReactNode } from 'react'

import { Text } from 'components/catalyst/text'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'

export function CommonTooltip(props: {
  children: ReactNode
  enabled?: boolean
  position?: 'bottom' | 'top' | 'right' | 'left'
  content: ReactNode | string
  className?: string
}) {
  const { enabled = true } = props

  if (!enabled) {
    return <>{props.children}</>
  }

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger className={props.className}>{props.children}</TooltipTrigger>
        <TooltipContent side={props.position ?? 'bottom'}>
          {typeof props.content === 'string' ? <Text className="!text-xs">{props.content}</Text> : props.content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
