import { AccountVerificationStatus, useAuthStore } from '@vectroid/shared/auth'
import { AUTH_ROUTES, RouteNames } from '@vectroid/shared/const'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function useAuthRedirect() {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const verificationStatus = useAuthStore((state) => state.account?.verificationStatus)
  const navigate = useNavigate()
  const location = useLocation()

  const isAccountVerified = verificationStatus === AccountVerificationStatus.VERIFIED

  useEffect(() => {
    const isAuthRoute = AUTH_ROUTES.includes(location.pathname)
    const attemptedPath = location.state?.from?.pathname

    if (location.pathname === RouteNames.VerifyAccount) return

    if (!isAuthenticated && !isAuthRoute) {
      navigate(RouteNames.Login, { state: { from: location } })
    } else if (isAuthenticated && isAuthRoute) {
      navigate(RouteNames.Dashboard)
    } else if (isAuthenticated && attemptedPath && attemptedPath !== location.pathname) {
      navigate(attemptedPath)
    }
  }, [isAuthenticated, navigate, location])

  return {
    isAuthenticated,
    isAccountVerified,
  }
}
