import * as React from 'react'

import { RouteNames } from '@vectroid/shared/const'
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command'

import { appConfig } from '@vectroid/shared/config'
import { getSidebarMenuItems } from 'const/sidebar'
import { DatabaseZapIcon, FolderTree, Search } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export function CommandBar() {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const CommandItems = [
    {
      heading: 'Suggestions',
      items: [
        { text: 'Create New Dataset', href: RouteNames.CreateDataset, icon: <FolderTree size={16} /> },
        { text: 'Create New Index', href: RouteNames.CreateIndex, icon: <DatabaseZapIcon size={16} /> },
        //   @ts-ignore
        ...getSidebarMenuItems(appConfig)[0],
      ],
    },
  ]

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === '/') {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  const onSelect = (menuItem: any) => {
    if (menuItem.onSelect) {
      menuItem.onSelect()
    } else {
      navigate(menuItem.href)
    }
    setOpen(false)
  }

  const renderMenuItems = () => {
    return CommandItems.map((menuItem, index) => {
      return (
        <CommandGroup heading={menuItem.heading} key={`CommandGroup-${index}`}>
          {menuItem.items.map((item) => {
            return (
              <CommandItem onSelect={() => onSelect(item)} key={item.text}>
                {React.cloneElement(item.icon, {
                  className: 'mr-2 h-4 w-4',
                })}
                <span>{item.text}</span>
              </CommandItem>
            )
          })}
        </CommandGroup>
      )
    })
  }

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className={
          'flex h-10 cursor-text items-center rounded-lg border px-3 hover:bg-zinc-100 dark:border-zinc-800 dark:bg-zinc-950 dark:hover:bg-zinc-800/90 lg:min-w-[80px]'
        }
      >
        <span className="flex items-center">
          <Search size={16} />
        </span>
        <span className={'flex-1'}></span>
        <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted/50 px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
          <span className="text-xs">/</span>
        </kbd>
      </button>

      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {renderMenuItems()}
        </CommandList>
      </CommandDialog>
    </>
  )
}
