import { Navigate, RouteObject } from 'react-router-dom'

import { RouteNames } from '@vectroid/shared/const'
import DatasetsPage from './pages'
import CreateDatasetPage from './pages/create'
import { DatasetDetailPage } from './pages/detail'
import { DatasetDetailBulkUploadsPage } from './pages/detail/bulk-uploads'
import { DatasetDetailOverviewPage } from './pages/detail/overview'

const routes: RouteObject[] = [
  {
    path: RouteNames.Datasets,
    element: <DatasetsPage />,
  },
  {
    path: RouteNames.CreateDataset,
    element: <CreateDatasetPage />,
  },
  {
    path: RouteNames.DatasetDetail.name,
    element: <DatasetDetailPage />,
    children: [
      {
        index: true,
        element: <Navigate to={RouteNames.DatasetDetailOverview.name} replace />,
      },
      {
        path: RouteNames.DatasetDetailOverview.name,
        element: <DatasetDetailOverviewPage />,
      },
      {
        path: RouteNames.DatasetDetailBulkUploads.name,
        element: <DatasetDetailBulkUploadsPage />,
      },
    ],
  },
]

export default routes
