import { cn } from '@vectroid/shared/utils'
import { Card } from 'components/catalyst/card'
import { Text } from 'components/catalyst/text'
import { ArrowRightIcon } from 'lucide-react'
import { Link } from 'react-router-dom'

type Props = {
  label: string
  href: string
  icon: React.ReactNode
  children?: React.ReactNode
  headerColor?: string
}

export function DashboardRecentItem(props: Props) {
  return (
    <Link to={props.href} className="group col-span-12 block flex-1 sm:col-span-4 lg:col-span-3">
      <Card className="!p-1">
        <div className={cn('flex h-full flex-col justify-between space-y-2 rounded-lg p-3', props.headerColor)}>
          <div className="flex items-start justify-between">
            <span className="flex-1">{props.icon}</span>
            <div>{props.children}</div>
          </div>
          <div className="flex items-center">
            <Text className="flex-1 font-semibold text-zinc-900 transition-all group-hover:text-zinc-600 dark:text-zinc-300 group-hover:dark:text-zinc-400 sm:text-base">
              {props.label}
            </Text>
            <ArrowRightIcon size={16} />
          </div>
        </div>
      </Card>
    </Link>
  )
}
