export enum CollectionType {
  // Dataset
  CREATE_DATASET = 'create-dataset',
  LIST_DATASETS = 'list-datasets',
  DATASET_INSERT = 'dataset-insert',
  DESCRIBE_DATASET = 'describe-dataset',

  // Index
  CREATE_INDEX = 'create-index',
  DESCRIBE_INDEX = 'describe-index',
  LIST_INDEXES = 'list-indexes',
  DROP_INDEX = 'drop-index',

  // Vector
  SEARCH_VECTOR = 'search-vector',
}

export interface Collection {
  method: string
  label: string
  endpoint: string
  disabled?: boolean
  type: CollectionType
  snippets?: {
    json?: any
  }
  dataCellResource?: boolean
}
