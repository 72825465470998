import { cn } from '@vectroid/shared/utils'
import { Text } from 'components/catalyst/text'
import { DatasetSelectComboBox } from 'modules/datasets/components'
import { useCollectionStore } from 'modules/indexes'

export function ConsoleHeaderDatasetSelector() {
  const { activeCollection, selectedDataset, setSelectedDataset } = useCollectionStore()

  return (
    <div
      className={cn(
        '-mt-4 mr-4 flex min-w-[280px] items-center space-x-4',
        !activeCollection.dataCellResource && 'hidden'
      )}
    >
      <Text>Select Dataset:</Text>
      <div className="flex-1">
        <DatasetSelectComboBox
          value={selectedDataset?.name}
          showDatasetTypeSelector={false}
          onChange={(dataset) => setSelectedDataset(dataset)}
        />
      </div>
    </div>
  )
}
