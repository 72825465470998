export const JavascriptFetchSnippet = {
  POST: `const url = '{{url}}';
const options = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': '{{apiKey}}',
  },
  body: JSON.stringify({{body}})
};

try {
  const response = await fetch(url, options);
  const data = await response.json();
  console.log(data);
} catch (error) {
  console.error(error);
}`,

  GET: `const url = '{{url}}';
const options = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'x-api-key': '{{apiKey}}',
  }
};

try {
  const response = await fetch(url, options);
  const data = await response.json();
  console.log(data);
} catch (error) {
  console.error(error);
}`,

  PUT: `const url = '{{url}}';
const options = {
  method: 'PUT',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': '{{apiKey}}',
  },
  body: JSON.stringify({{body}})
};

try {
  const response = await fetch(url, options);
  const data = await response.json();
  console.log(data);
} catch (error) {
  console.error(error);
}`,

  DELETE: `const url = '{{url}}';
const options = {
  method: 'DELETE',
  headers: {
    Accept: 'application/json',
    'x-api-key': '{{apiKey}}',
  }
};

try {
  const response = await fetch(url, options);
  const data = await response.json();
  console.log(data);
} catch (error) {
  console.error(error);
}`,
}

export const JavascriptAxiosSnippet = {
  POST: `import axios from 'axios';

const options = {
  method: 'POST',
  url: '{{url}}',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': '{{apiKey}}',
  },
  data: {{body}}
};

try {
  const { data } = await axios.request(options);
  console.log(data);
} catch (error) {
  console.error(error);
}`,

  GET: `import axios from 'axios';

const options = {
  method: 'GET',
  url: '{{url}}',
  headers: {
    Accept: 'application/json',
    'x-api-key': '{{apiKey}}',
  }
};

try {
  const { data } = await axios.request(options);
  console.log(data);
} catch (error) {
  console.error(error);
}`,

  PUT: `import axios from 'axios';

const options = {
  method: 'PUT',
  url: '{{url}}',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': '{{apiKey}}',
  },
  data: {{body}}
};

try {
  const { data } = await axios.request(options);
  console.log(data);
} catch (error) {
  console.error(error);
}`,

  DELETE: `import axios from 'axios';

const options = {
  method: 'DELETE',
  url: '{{url}}',
  headers: {
    Accept: 'application/json',
    'x-api-key': '{{apiKey}}',
  }
};

try {
  const { data } = await axios.request(options);
  console.log(data);
} catch (error) {
  console.error(error);
}`,
}

export const JavascriptJQuerySnippet = {
  POST: `const settings = {
  async: true,
  crossDomain: true,
  url: '{{url}}',
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': '{{apiKey}}',
  },
  data: JSON.stringify({{body}})
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`,

  GET: `const settings = {
  async: true,
  crossDomain: true,
  url: '{{url}}',
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'x-api-key': '{{apiKey}}',
  }
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`,

  PUT: `const settings = {
  async: true,
  crossDomain: true,
  url: '{{url}}',
  method: 'PUT',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': '{{apiKey}}',
  },
  data: JSON.stringify({{body}})
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`,

  DELETE: `const settings = {
  async: true,
  crossDomain: true,
  url: '{{url}}',
  method: 'DELETE',
  headers: {
    Accept: 'application/json',
    'x-api-key': '{{apiKey}}',
  }
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`,
}

export const JavaScriptXMLHttpRequestSnippet = {
  POST: `const data = JSON.stringify({{body}});

const xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener('readystatechange', function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open('POST', '{{url}}');
xhr.setRequestHeader('x-api-key', '{{apiKey}}');
xhr.setRequestHeader('Accept', 'application/json');
xhr.setRequestHeader('Content-Type', 'application/json');

xhr.send(data);`,

  GET: `const xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener('readystatechange', function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open('GET', '{{url}}');
xhr.setRequestHeader('x-api-key', '{{apiKey}}');
xhr.setRequestHeader('Accept', 'application/json');

xhr.send();`,

  PUT: `const data = JSON.stringify({{body}});

const xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener('readystatechange', function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open('PUT', '{{url}}');
xhr.setRequestHeader('x-api-key', '{{apiKey}}');
xhr.setRequestHeader('Accept', 'application/json');
xhr.setRequestHeader('Content-Type', 'application/json');

xhr.send(data);`,

  DELETE: `const xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener('readystatechange', function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open('DELETE', '{{url}}');
xhr.setRequestHeader('x-api-key', '{{apiKey}}');
xhr.setRequestHeader('Accept', 'application/json');

xhr.send();`,
}
