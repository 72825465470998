import { useWarnIfUploading } from 'hooks/use-warn-if-uploading'
import { Minus, Plus, X } from 'lucide-react'
import { UploadStatus } from 'modules/datasets/const/upload-status.enum'
import { useTimeLeft } from 'modules/datasets/hooks/use-time-left'
import { useDatasetBulkUploadStore } from 'modules/datasets/store/dataset-bulk-upload.store'
import { useEffect, useState } from 'react'

import { UploadProgressBar } from './upload-progress-bar'
import { UploadProgressContent } from './upload-progress-content'
import { UploadProgressHeader } from './upload-progress-header'

export function UploadProgress() {
  useWarnIfUploading()

  const { uploadProgress, currentFile, uploadStatus, bytesUploaded, uploadSpeed, dataset, cancelUpload, reset, error } =
    useDatasetBulkUploadStore()

  const [isMinimized, setIsMinimized] = useState(false)
  const timeLeft = useTimeLeft(currentFile, uploadSpeed, bytesUploaded)

  // Automatically expand the panel when the upload is complete.
  useEffect(() => {
    if (uploadStatus === UploadStatus.Completed && isMinimized) {
      setIsMinimized(false)
    }
  }, [uploadStatus, isMinimized])

  // Do not render if not uploading or no file is selected.
  if (uploadStatus === UploadStatus.Idle || !currentFile) return null

  const isCompleted = uploadStatus === UploadStatus.Completed
  const isUploading = uploadStatus === UploadStatus.Uploading

  const handleClose = () => {
    if (!isUploading) {
      reset()
    } else {
      const userConfirmed = window.confirm('Are you sure you want to cancel the upload?')
      if (userConfirmed) {
        cancelUpload()
      }
    }
  }

  return (
    <div className="fixed bottom-10 right-10 z-[9999] w-[450px] rounded-lg border border-gray-200 bg-white shadow-lg transition-all duration-300 dark:border-zinc-700 dark:bg-zinc-800">
      {/* Header Area */}
      <div className="flex items-center justify-between border-b border-gray-100 p-4 dark:border-zinc-700">
        <UploadProgressHeader
          isMinimized={isMinimized}
          uploadStatus={uploadStatus}
          currentFile={currentFile}
          dataset={dataset}
          timeLeft={timeLeft}
          error={error}
        />
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setIsMinimized(!isMinimized)}
            className="inline-flex items-center rounded p-1 text-zinc-500 transition-colors hover:text-emerald-600 focus:outline-none"
            title={isMinimized ? 'Expand' : 'Minimize'}
          >
            {isMinimized ? <Plus size={16} /> : !isCompleted ? <Minus size={16} /> : null}
          </button>
          <button
            onClick={handleClose}
            className="inline-flex items-center rounded p-1 text-zinc-500 transition-colors hover:text-red-600 focus:outline-none"
            title={isCompleted ? 'Close' : 'Cancel upload'}
          >
            <X size={16} />
          </button>
        </div>
      </div>

      {/* Expanded Content Container */}
      <div
        className={`overflow-hidden transition-all duration-300 ${
          isMinimized ? 'max-h-0 opacity-0' : 'max-h-96 opacity-100'
        }`}
      >
        <UploadProgressContent
          currentFile={currentFile}
          isCompleted={isCompleted}
          bytesUploaded={bytesUploaded}
          dataset={dataset}
        />
      </div>

      {/* Progress Bar */}
      <UploadProgressBar uploadProgress={uploadProgress} isMinimized={isMinimized} isCompleted={isCompleted} />
    </div>
  )
}
