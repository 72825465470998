import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { postWindowEvent, WindowEventTypes } from '../events'
import { Account } from './types'

type State = {
  isAuthenticated: boolean
  account: Account | null
  accessToken: string | null
  refreshToken: string | null
}

export type LoginPayload = {
  accessToken: string | null
  refreshToken: string | null
  account: Account
}

type Action = {
  login: (payload: LoginPayload) => void
  logout: () => void
  setAccessToken: (token: string) => void
  setRefreshToken: (token: string) => void
}

const useAuthStore = create<State & Action>()(
  devtools(
    persist(
      (set) => ({
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        account: null,

        // Actions
        login: (payload) => {
          if (window.opener) {
            postWindowEvent(WindowEventTypes.Auth, payload, true)
            return
          }
          set({ ...payload, isAuthenticated: true })
        },

        logout: () =>
          set({
            accessToken: null,
            refreshToken: null,
            account: null,
            isAuthenticated: false,
          }),

        setAccessToken: (token: string) =>
          set({
            accessToken: token,
          }),

        setRefreshToken: (token: string) =>
          set({
            refreshToken: token,
          }),
      }),
      {
        name: 'auth-storage',
      }
    )
  )
)

export { useAuthStore }
