import { useAuthStore } from '@vectroid/shared/auth'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const Logout: React.FC = () => {
  const logout = useAuthStore((state) => state.logout)
  const location = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    const redirect = params.get('redirect') || '/console'

    logout()
    navigate(redirect)
  }, [logout, location.search, navigate])

  return null
}

export default Logout
