export const JavaAsyncHTTPClientSnippet = {
  POST: `AsyncHttpClient client = new DefaultAsyncHttpClient();
client.prepare("POST", "{{url}}")
  .setHeader("x-api-key", "{{apiKey}}")
  .setHeader("Accept", "application/json")
  .setHeader("Content-Type", "application/json")
  .setBody("{{body}}")
  .execute()
  .toCompletableFuture()
  .thenAccept(System.out::println)
  .join();

client.close();`,

  GET: `AsyncHttpClient client = new DefaultAsyncHttpClient();
client.prepare("GET", "{{url}}")
  .setHeader("x-api-key", "{{apiKey}}")
  .setHeader("Accept", "application/json")
  .execute()
  .toCompletableFuture()
  .thenAccept(System.out::println)
  .join();

client.close();`,

  PUT: `AsyncHttpClient client = new DefaultAsyncHttpClient();
client.prepare("PUT", "{{url}}")
  .setHeader("x-api-key", "{{apiKey}}")
  .setHeader("Accept", "application/json")
  .setHeader("Content-Type", "application/json")
  .setBody("{{body}}")
  .execute()
  .toCompletableFuture()
  .thenAccept(System.out::println)
  .join();

client.close();`,

  DELETE: `AsyncHttpClient client = new DefaultAsyncHttpClient();
client.prepare("DELETE", "{{url}}")
  .setHeader("x-api-key", "{{apiKey}}")
  .setHeader("Accept", "application/json")
  .execute()
  .toCompletableFuture()
  .thenAccept(System.out::println)
  .join();

client.close();`,
}

export const JavaNetHttpSnippet = {
  POST: `HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create("{{url}}"))
    .header("x-api-key", "{{apiKey}}")
    .header("Accept", "application/json")
    .header("Content-Type", "application/json")
    .method("POST", HttpRequest.BodyPublishers.ofString("{{body}}"))
    .build();
HttpResponse<String> response = HttpClient.newHttpClient().send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());`,

  GET: `HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create("{{url}}"))
    .header("x-api-key", "{{apiKey}}")
    .header("Accept", "application/json")
    .GET()
    .build();
HttpResponse<String> response = HttpClient.newHttpClient().send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());`,

  PUT: `HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create("{{url}}"))
    .header("x-api-key", "{{apiKey}}")
    .header("Accept", "application/json")
    .header("Content-Type", "application/json")
    .method("PUT", HttpRequest.BodyPublishers.ofString("{{body}}"))
    .build();
HttpResponse<String> response = HttpClient.newHttpClient().send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());`,

  DELETE: `HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create("{{url}}"))
    .header("x-api-key", "{{apiKey}}")
    .header("Accept", "application/json")
    .DELETE()
    .build();
HttpResponse<String> response = HttpClient.newHttpClient().send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());`,
}

export const JavaOkHttpSnippet = {
  POST: `OkHttpClient client = new OkHttpClient();
RequestBody body = RequestBody.create(MediaType.parse("application/json"), "{{body}}");
Request request = new Request.Builder()
  .url("{{url}}")
  .post(body)
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();`,

  GET: `OkHttpClient client = new OkHttpClient();
Request request = new Request.Builder()
  .url("{{url}}")
  .get()
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .build();
Response response = client.newCall(request).execute();`,

  PUT: `OkHttpClient client = new OkHttpClient();
RequestBody body = RequestBody.create(MediaType.parse("application/json"), "{{body}}");
Request request = new Request.Builder()
  .url("{{url}}")
  .put(body)
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();`,

  DELETE: `OkHttpClient client = new OkHttpClient();
Request request = new Request.Builder()
  .url("{{url}}")
  .delete()
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .build();
Response response = client.newCall(request).execute();`,
}

export const JavaUnirestSnippet = {
  POST: `HttpResponse<String> response = Unirest.post("{{url}}")
  .header("x-api-key", "{{apiKey}}")
  .header("Accept", "application/json")
  .header("Content-Type", "application/json")
  .body("{{body}}")
  .asString();`,

  GET: `HttpResponse<String> response = Unirest.get("{{url}}")
  .header("x-api-key", "{{apiKey}}")
  .header("Accept", "application/json")
  .asString();`,

  PUT: `HttpResponse<String> response = Unirest.put("{{url}}")
  .header("x-api-key", "{{apiKey}}")
  .header("Accept", "application/json")
  .header("Content-Type", "application/json")
  .body("{{body}}")
  .asString();`,

  DELETE: `HttpResponse<String> response = Unirest.delete("{{url}}")
  .header("x-api-key", "{{apiKey}}")
  .header("Accept", "application/json")
  .asString();`,
}
