export enum Region {
  US_EAST = 'US_EAST',
}

export enum CloudProvider {
  GCP = 'GOOGLE',
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum DatasetStatus {
  PROVISIONING = 'PROVISIONING',
  ACTIVE = 'ACTIVE',
}

export enum DatasetCreateSteps {
  DATASET_CREATE = 0,
  INSERT_DATA,
  CREATE_INDEX,
  QUERY,
}

export enum DatasetBulkUploadStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export const DatasetCreateStepsLength = 4
