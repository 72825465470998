import { RouteNames } from '@vectroid/shared/const'
import { cn } from '@vectroid/shared/utils'
import { Divider } from 'components/catalyst/divider'
import { Text } from 'components/catalyst/text'
import { useDocsPanel } from 'context/docs-provider'
import { ArrowRightIcon, BookOpenIcon, Braces, FileSearch, FolderOpenDot } from 'lucide-react'
import { useAuthStore } from 'modules/auth'
import { useNavigate } from 'react-router-dom'

interface StepItem {
  number?: number
  title: string
  href?: string
  onClick?: () => void
  description: string
  icon?: React.ReactNode
}

type StepDefinition = StepItem | StepItem[]

const internalSteps: StepItem[] = [
  {
    number: 1,
    title: 'Create a dataset',
    href: `${RouteNames.Datasets}?create=true`,
    description: 'A dataset is the foundation for indexing and querying vectors.',
  },
  {
    number: 2,
    title: 'Add data into your dataset',
    description: 'Add your vectors into the created dataset to prepare for indexing.',
  },
  {
    number: 3,
    title: 'Index your dataset',
    href: RouteNames.CreateIndex,
    description: 'Transform raw vectors into a queryable format optimized for performance.', // Slightly rephrased
  },
  {
    number: 4,
    title: 'Query',
    href: `${RouteNames.ApiPlayground}?type=search-vector`,
    description: 'Run queries on indexed vectors to discover similarities and insights.',
  },
]

export function DashboardEmptyState() {
  const navigate = useNavigate()
  const account = useAuthStore((state) => state.account)
  const { openDocsPanel } = useDocsPanel()

  const docSteps: StepDefinition[] = [
    [
      {
        icon: <BookOpenIcon className="size-6" />,
        title: 'Quickstart Guide',
        onClick: () => openDocsPanel('/quickstart'),
        description: 'Follow a step-by-step tutorial to get started.',
      },
      {
        icon: <Braces className="size-6" />,
        title: 'API Reference',
        onClick: () => openDocsPanel('/'),
        description: 'Detailed info on available API endpoints.',
      },
    ],
    [
      {
        icon: <FolderOpenDot className="size-6" />,
        title: 'Create Dataset Docs',
        onClick: () => openDocsPanel('/create-dataset'),
        description: 'Learn the fundamentals of datasets.',
      },
      {
        icon: <FolderOpenDot className="size-6" />,
        title: 'Insert Data Docs', // Updated title
        onClick: () => openDocsPanel('/dataset-insert'),
        description: 'Understand how to populate your datasets.',
      },
    ],
    [
      {
        icon: <FileSearch className="size-6" />,
        title: 'Create Index Docs',
        onClick: () => openDocsPanel('/create-index'),
        description: 'Explore methods for indexing data.',
      },
      {
        icon: <FileSearch className="size-6" />,
        title: 'Search Vector Docs',
        onClick: () => openDocsPanel('/search-vector'),
        description: 'Discover how to query indexed vectors.',
      },
    ],
  ]

  const renderStepItem = (step: StepItem, isInRow = false) => {
    const isClickable = step.href || step.onClick
    const commonClasses = cn(
      'group block rounded-xl p-4 transition-all border border-transparent hover:bg-zinc-200/50 dark:hover:bg-zinc-900/60',
      isClickable && 'hover:border-zinc-300 hover:dark:border-zinc-700',
      isClickable ? 'cursor-pointer' : 'pointer-events-none'
    )

    const content = (
      <div className="flex items-start sm:items-center">
        {step.number && (
          <span
            className={cn(
              'mr-4 flex size-10 shrink-0 items-center justify-center rounded-full font-semibold transition-all sm:size-12',
              'bg-emerald-100 text-emerald-700 dark:bg-emerald-800 dark:text-emerald-100'
            )}
          >
            {step.number}
          </span>
        )}
        {step.icon && (
          <span
            className={cn(
              'mr-4 flex size-10 shrink-0 items-center justify-center rounded-full transition-all sm:size-12',
              'bg-sky-100 text-sky-700 dark:bg-sky-900/50 dark:text-sky-300'
            )}
          >
            {step.icon}
          </span>
        )}
        <div className="flex-1">
          <span className="font-bold text-zinc-900 dark:text-white">{step.title}</span>
          <Text className="!text-sm">{step.description}</Text>
        </div>
        {isClickable && (
          <ArrowRightIcon className="ml-4 size-5 self-center text-zinc-400 transition-colors group-hover:text-zinc-700 dark:text-zinc-500 dark:group-hover:text-zinc-300 sm:size-6" />
        )}
      </div>
    )

    const handleClick = () => {
      if (step.onClick) {
        step.onClick()
      } else if (step.href) {
        if (step.href.startsWith('http')) {
          window.open(step.href, '_blank', 'noopener noreferrer')
        } else {
          navigate(step.href)
        }
      }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (isClickable && (e.key === 'Enter' || e.key === ' ')) {
        e.preventDefault()
        handleClick()
      }
    }

    return (
      <div
        className={commonClasses}
        role={isClickable ? 'button' : undefined}
        tabIndex={isClickable ? 0 : -1}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        aria-label={step.title}
      >
        {content}
      </div>
    )
  }

  const renderStepOrRow = (stepOrRow: StepDefinition, index: number, totalItems: number) => {
    const isLastItem = index === totalItems - 1

    if (Array.isArray(stepOrRow)) {
      return (
        <div key={`row-${index}`}>
          <div className="flex flex-col gap-4 md:flex-row md:gap-4">
            {' '}
            {stepOrRow.map((subStep, subIndex) => (
              <div
                key={subStep.title || `row-${index}-item-${subIndex}`}
                className={cn({
                  'flex-1': true,
                  'border-r pr-4': subIndex === 0,
                })}
              >
                {renderStepItem(subStep, true)}
              </div>
            ))}
          </div>
          {!isLastItem && <Divider className="my-4" />}
        </div>
      )
    } else {
      return (
        <div key={stepOrRow.title || `item-${index}`}>
          {renderStepItem(stepOrRow, false)}
          {!isLastItem && <Divider className="my-4" />}
        </div>
      )
    }
  }

  return (
    <>
      <Text className="mb-6 !text-base font-medium">
        🎉 Welcome to Vectroid{account?.name ? `, ${account.name}` : ''}! Let's get your first dataset and index set up.
      </Text>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-12">
        <div className="flex flex-col rounded-xl border border-zinc-300 p-4 dark:border-zinc-800 lg:col-span-5 xl:col-span-4">
          <Text className="mb-4">Get Started</Text>
          {internalSteps.map((step, index) => (
            <div key={step.title || `internal-${index}`}>
              {renderStepItem(step)}
              {index < internalSteps.length - 1 && <Divider className="my-4" />}
            </div>
          ))}
        </div>

        <div className="flex flex-col rounded-xl border border-zinc-300 p-4 dark:border-zinc-800 lg:col-span-7 xl:col-span-8">
          <Text className="mb-4">Documentation</Text>
          {docSteps.map((stepOrRow, index) => renderStepOrRow(stepOrRow, index, docSteps.length))}
        </div>
      </div>
    </>
  )
}
