export const GoSnippet = {
  POST: `package main

  import (
	  "fmt"
	  "strings"
	  "net/http"
	  "io"
  )

  func main() {
	  url := "{{url}}"
	  payload := strings.NewReader("{{body}}")

	  req, _ := http.NewRequest("POST", url, payload)
	  req.Header.Add("x-api-key", "{{apiKey}}")
	  req.Header.Add("Accept", "application/json")
	  req.Header.Add("Content-Type", "application/json")

	  res, _ := http.DefaultClient.Do(req)
	  defer res.Body.Close()
	  body, _ := io.ReadAll(res.Body)

	  fmt.Println(res)
	  fmt.Println(string(body))
  }`,

  GET: `package main

  import (
	  "fmt"
	  "net/http"
	  "io"
  )

  func main() {
	  url := "{{url}}"

	  req, _ := http.NewRequest("GET", url, nil) // No body for GET requests
	  req.Header.Add("x-api-key", "{{apiKey}}")
	  req.Header.Add("Accept", "application/json")

	  res, _ := http.DefaultClient.Do(req)
	  defer res.Body.Close()
	  body, _ := io.ReadAll(res.Body)

	  fmt.Println(res)
	  fmt.Println(string(body))
  }`,

  PUT: `package main

  import (
	  "fmt"
	  "strings"
	  "net/http"
	  "io"
  )

  func main() {
	  url := "{{url}}"
	  payload := strings.NewReader("{{body}}")

	  req, _ := http.NewRequest("PUT", url, payload)
	  req.Header.Add("x-api-key", "{{apiKey}}")
	  req.Header.Add("Accept", "application/json")
	  req.Header.Add("Content-Type", "application/json")

	  res, _ := http.DefaultClient.Do(req)
	  defer res.Body.Close()
	  body, _ := io.ReadAll(res.Body)

	  fmt.Println(res)
	  fmt.Println(string(body))
  }`,

  DELETE: `package main

  import (
	  "fmt"
	  "net/http"
	  "io"
  )

  func main() {
	  url := "{{url}}"

	  req, _ := http.NewRequest("DELETE", url, nil) // No body for DELETE requests
	  req.Header.Add("x-api-key", "{{apiKey}}")
	  req.Header.Add("Accept", "application/json")

	  res, _ := http.DefaultClient.Do(req)
	  defer res.Body.Close()
	  body, _ := io.ReadAll(res.Body)

	  fmt.Println(res)
	  fmt.Println(string(body))
  }`,
}
