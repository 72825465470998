import { cn, formatBytes } from '@vectroid/shared/utils'
import { Button } from 'components/catalyst/button'
import { Text } from 'components/catalyst/text'
import { File as FileIcon, HardDriveUpload } from 'lucide-react'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'

export interface FileUploaderProps {
  fileType?: string
  onFileSelected?: (files: FileList) => void
  onDragOver?: (event: React.DragEvent<HTMLDivElement>) => void
  onDragEnter?: (event: React.DragEvent<HTMLDivElement>) => void
  onDragLeave?: (event: React.DragEvent<HTMLDivElement>) => void
  onDrop?: (files: FileList) => void
}

export const FileUploader = forwardRef(function FileUploader(
  { fileType, onFileSelected, onDragOver, onDragEnter, onDragLeave, onDrop }: FileUploaderProps,
  ref
) {
  const [isDragging, setIsDragging] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFiles = (files: FileList) => {
    const file = files[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
    onDragOver && onDragOver(e)
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
    onDragEnter && onDragEnter(e)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    onDragLeave && onDragLeave(e)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    const files = e.dataTransfer.files
    handleFiles(files)
    onDrop && onDrop(files)
    onFileSelected && onFileSelected(files)
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      handleFiles(files)
      onFileSelected && onFileSelected(files)
    }
  }

  const handleClick = () => {
    fileInputRef.current?.click()
  }

  // Reset function to clear selected file
  useImperativeHandle(ref, () => ({
    reset: () => {
      setSelectedFile(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    },
  }))

  return (
    <div className="relative">
      {!selectedFile && (
        <div
          className={cn(
            'group flex w-full transform cursor-pointer flex-col items-center justify-center space-y-6 rounded-xl border border-dashed p-12 text-center transition-all duration-300',
            {
              'border-emerald-500 dark:border-emerald-500': isDragging,
              'border-zinc-400 hover:border-zinc-600 dark:border-zinc-600 dark:hover:border-zinc-400': !isDragging,
            }
          )}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          <div
            className={cn(
              'flex items-center justify-center rounded-full border p-4 transition-all duration-300 group-hover:rotate-12',
              {
                'translate-y-1 border-emerald-500 dark:border-emerald-500': isDragging,
                'border-zinc-400 dark:border-zinc-600': !isDragging,
              }
            )}
          >
            <HardDriveUpload
              size={36}
              strokeWidth={1.5}
              className={cn('transition-all', { '!text-emerald-500': isDragging })}
            />
          </div>
          <Text
            className={cn('transition-all', {
              '-translate-y-1 !text-emerald-500': isDragging,
              'group-hover:-translate-y-1': !isDragging,
            })}
          >
            Drag here your files or click to upload <br />
            <span className="text-xs opacity-60">{fileType}</span>
          </Text>
        </div>
      )}
      <input type="file" accept={fileType} ref={fileInputRef} onChange={handleFileSelect} className="hidden" />

      {/* Preview Section */}
      {selectedFile && (
        <div className="mt-4 flex items-start rounded-xl border p-4">
          <div className="flex flex-1 items-start space-x-4 pr-4">
            <div className="rounded-lg border p-2">
              <FileIcon size={24} />
            </div>
            <div className="flex flex-col">
              <Text className="font-semibold">{selectedFile.name}</Text>
              <Text className="text-sm text-gray-600">
                {selectedFile.type || 'Unknown type'} • {formatBytes(selectedFile.size)}
              </Text>
            </div>
          </div>
          <Button outline onClick={handleClick}>
            Change File
          </Button>
        </div>
      )}
    </div>
  )
})
