import { Button } from 'components/catalyst/button'
import { Text } from 'components/catalyst/text'
import { Textarea } from 'components/catalyst/textarea'
import { useNotificationStore } from 'components/common'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { AnimatePresence, motion } from 'framer-motion'
import { CheckCircle2, LightbulbIcon } from 'lucide-react'
import { useAuthStore } from 'modules/auth'
import { useState } from 'react'
import { sendFeedbackApi } from './feedback.service'

export function FeedbackWidget() {
  return (
    <Popover>
      <PopoverTrigger>
        <div className="flex h-10 cursor-pointer items-center rounded-lg border border-zinc-200 px-3 transition-all hover:bg-zinc-100 dark:border-zinc-800 dark:hover:bg-zinc-800/90 lg:min-w-[80px]">
          <LightbulbIcon className="mr-2 size-3.5" />
          <Text>Feedback</Text>
        </div>
      </PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="rounded-xl">
        <Feedback />
      </PopoverContent>
    </Popover>
  )
}

const Feedback = () => {
  const [feedback, setFeedback] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const email = useAuthStore((state) => state.account?.email)

  const { setMessage } = useNotificationStore()

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await sendFeedbackApi({ message: feedback, email: email || '' })
      if (response.data.success) {
        setSubmitted(true)
        setFeedback('')
      } else {
        setMessage("Couldn't send feedback. Please try again later.", {
          type: 'error',
        })
      }
    } catch (error) {
      console.error(error)
      setMessage("Couldn't send feedback. Please try again later.", {
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      if (feedback.trim()) {
        handleSubmit(e)
      }
    }
  }

  return (
    <div className="-m-1 w-[calc(100%_+_8px)]">
      <AnimatePresence mode="wait">
        {!submitted ? (
          <motion.form
            key="form"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
            className="flex flex-col space-y-3"
            onSubmit={handleSubmit}
          >
            <Textarea
              placeholder="Ideas on how to enhance Vectroid.."
              value={feedback}
              rows={4}
              onChange={(e) => setFeedback(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div className="flex justify-end">
              <Button loading={loading} onClick={handleSubmit} disabled={feedback.trim() === ''} className="!text-xs">
                Send Feedback
              </Button>
            </div>
          </motion.form>
        ) : (
          <motion.div
            key="thank-you"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.25, ease: 'easeOut' }}
            className="flex flex-col items-center justify-center space-y-2 p-2 text-center"
          >
            <motion.div animate={{ rotate: 360 }} transition={{ duration: 0.5, ease: 'easeOut' }}>
              <CheckCircle2 className="text-green-700 dark:text-green-500" size={24} />
            </motion.div>
            <h2 className="font-semibold text-zinc-800 dark:text-white">Thank You!</h2>
            <p className="text-sm text-zinc-500 dark:text-zinc-400">Your feedback helps us improve.</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
