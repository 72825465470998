import axios from 'axios'

/**
 * Uploads a file chunk with retry logic and progress/speed updates.
 */
export async function uploadChunk(
  sessionUrl: string,
  file: File,
  startByte: number,
  endByte: number,
  chunkIndex: number,
  headers: Record<string, string>,
  onProgress: (loaded: number, speed?: number) => void,
  cancelToken: any,
  maxRetries = 3,
  initialDelay = 1000
): Promise<void> {
  const chunk = file.slice(startByte, endByte)
  let attempt = 0
  let delay = initialDelay

  while (attempt < maxRetries) {
    try {
      let lastTime = Date.now()
      let lastLoaded = 0

      await axios.put(sessionUrl, chunk, {
        headers,
        onUploadProgress: (progressEvent) => {
          const now = Date.now()
          const timeDiff = (now - lastTime) / 1000 // seconds
          let speed = 0
          if (timeDiff > 0) {
            const bytesDiff = progressEvent.loaded - lastLoaded
            speed = bytesDiff / timeDiff
          }
          lastTime = now
          lastLoaded = progressEvent.loaded

          // Update the progress and speed via callback
          onProgress(progressEvent.loaded, speed)
        },
        validateStatus: (status) => (status >= 200 && status < 300) || status === 308,
        transformRequest: [
          (data, headers) => {
            // Remove default Accept header if present
            delete headers.Accept
            return data
          },
        ],
        cancelToken,
      })

      return // Successful upload; exit the function.
    } catch (error) {
      attempt++
      if (axios.isCancel(error)) {
        throw error
      }
      if (attempt >= maxRetries) {
        throw error
      }
      // Wait for an exponentially increasing delay before retrying
      await new Promise((resolve) => setTimeout(resolve, delay))
      delay *= 2
    }
  }
}
