// src/hooks/useWarnIfUploading.ts
import { useDatasetBulkUploadStore } from 'modules/datasets/store/dataset-bulk-upload.store'
import { useEffect } from 'react'

export function useWarnIfUploading() {
  const uploadStatus = useDatasetBulkUploadStore((state) => state.uploadStatus)

  useEffect(() => {
    const handler = (e: BeforeUnloadEvent) => {
      if (uploadStatus === 'uploading') {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', handler)
    return () => window.removeEventListener('beforeunload', handler)
  }, [uploadStatus])
}
