import { ApiDocsButton } from 'components/api-docs-button'
import { Heading } from 'components/catalyst/heading'
import { Text } from 'components/catalyst/text'
import { useTabs } from 'hooks/use-tabs'
import { useEffect } from 'react'
import { CreateDatasetDialog } from '../components/create-dataset-dialog'
import { DatasetListView } from '../components/dataset-list-view'
import { DatasetsHeaderToolbar } from '../components/datasets-header-toolbar'
import { PublicDatasets } from '../components/public-datasets'
import { useDatasetStore } from '../store/dataset.store'

enum Tabs {
  MyDatasets = 'my-datasets',
  SharedWithMe = 'shared-with-me',
  PublicDatasets = 'public-datasets',
}

function DatasetsPage() {
  const { fetchDatasets } = useDatasetStore()

  const { tabs, currentTab } = useTabs([
    {
      label: 'My Datasets',
      href: Tabs.MyDatasets,
    },
    {
      label: 'Shared With Me',
      href: Tabs.SharedWithMe,
      enabled: false,
    },
    {
      label: 'Public Datasets',
      href: Tabs.PublicDatasets,
      enabled: false,
    },
  ])

  useEffect(() => {
    fetchDatasets()
  }, [])

  return (
    <>
      <Heading
        level={2}
        className="sm:text-lg/8"
        pageHeading
        actions={
          <>
            <ApiDocsButton path="/list-datasets" />
            <CreateDatasetDialog />
          </>
        }
        tabs={tabs}
      >
        Datasets
      </Heading>

      <DatasetsHeaderToolbar />

      {currentTab === Tabs.MyDatasets && <DatasetListView />}
      {currentTab === Tabs.SharedWithMe && <Text>No resources has been shared with you yet.</Text>}
      {currentTab === Tabs.PublicDatasets && <PublicDatasets />}
    </>
  )
}

export default DatasetsPage
