export const PythonNativeSnippet = {
  POST: `import http.client

conn = http.client.HTTPSConnection("{{host}}")

payload = '{{body}}'

headers = {
    'x-api-key': "{{apiKey}}",
    'Accept': "application/json",
    'Content-Type': "application/json"
}

conn.request("POST", "{{endpoint}}", payload, headers)

res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))`,

  GET: `import http.client

conn = http.client.HTTPSConnection("{{host}}")

headers = {
    'x-api-key': "{{apiKey}}",
    'Accept': "application/json"
}

conn.request("GET", "{{endpoint}}", headers=headers)

res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))`,

  PUT: `import http.client

conn = http.client.HTTPSConnection("{{host}}")

payload = '{{body}}'

headers = {
    'x-api-key': "{{apiKey}}",
    'Accept': "application/json",
    'Content-Type': "application/json"
}

conn.request("PUT", "{{endpoint}}", payload, headers)

res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))`,

  DELETE: `import http.client

conn = http.client.HTTPSConnection("{{host}}")

headers = {
    'x-api-key': "{{apiKey}}",
    'Accept': "application/json"
}

conn.request("DELETE", "{{endpoint}}", headers=headers)

res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))`,
}

export const PythonRequestsSnippet = {
  POST: `import requests

url = "{{url}}"

payload = '{{body}}'
headers = {
  "x-api-key": "{{apiKey}}",
  "Accept": "application/json",
  "Content-Type": "application/json"
}

response = requests.post(url, data=payload, headers=headers)

print(response.json())`,

  GET: `import requests

url = "{{url}}"

headers = {
  "x-api-key": "{{apiKey}}",
  "Accept": "application/json"
}

response = requests.get(url, headers=headers)

print(response.json())`,

  PUT: `import requests

url = "{{url}}"

payload = '{{body}}'
headers = {
  "x-api-key": "{{apiKey}}",
  "Accept": "application/json",
  "Content-Type": "application/json"
}

response = requests.put(url, data=payload, headers=headers)

print(response.json())`,

  DELETE: `import requests

url = "{{url}}"

headers = {
  "x-api-key": "{{apiKey}}",
  "Accept": "application/json"
}

response = requests.delete(url, headers=headers)

print(response.json())`,
}
