import { DatasetIndexHierarchyOverview } from 'modules/datasets/components/dataset-index-hierarchy-overview'
import { DatasetIndexesTable } from 'modules/datasets/components/dataset-indexes-table'
import { DatasetInsertEditor } from 'modules/datasets/components/dataset-insert-editor'
import { DatasetOverview } from 'modules/datasets/components/dataset-overview'

export function DatasetDetailOverviewPage() {
  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 sm:col-span-12 lg:col-span-7">
          <DatasetInsertEditor />
        </div>
        <div className="col-span-12 sm:col-span-12 lg:col-span-5">
          <DatasetOverview />
        </div>
        <div className="col-span-12 sm:col-span-12 lg:col-span-7">
          <DatasetIndexesTable />
        </div>
        <div className="col-span-12 sm:col-span-12 lg:col-span-5">
          <DatasetIndexHierarchyOverview />
        </div>
      </div>
    </>
  )
}
