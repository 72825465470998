import { apiClient } from '@vectroid/shared/api'
import { ListApiKeysResponse } from '../types'

/**
 * List Api keys for account
 *
 * @returns {Promise<ListApiKeysResponse>}
 */
export function listApiKeysApi() {
  return apiClient<{}, ListApiKeysResponse>('/api-keys', 'GET')
}
