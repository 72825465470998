import { DetailPageSkeleton } from 'components/skeletons'
import { useTrackRecentResources } from 'modules/dashboard/store'
import { DatasetDetailHeader } from 'modules/datasets/components/dataset-detail-header'
import { useDatasetDetail, useDatasetDetailStore } from 'modules/datasets/store/datase-detail.store'
import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

export function DatasetDetailPage() {
  const { datasetName } = useParams<{ datasetName: string }>()
  const { fetchDatasetDetailPage, datasetLoading } = useDatasetDetail(datasetName!)
  const dataset = useDatasetDetailStore((state) => state.dataset)
  useTrackRecentResources()

  useEffect(() => {
    if (datasetName) {
      fetchDatasetDetailPage()
    }
  }, [datasetName, fetchDatasetDetailPage])

  if (datasetLoading) {
    return <DetailPageSkeleton />
  }

  if (!dataset) {
    return null
  }

  return (
    <>
      <DatasetDetailHeader />

      <Outlet />
    </>
  )
}
