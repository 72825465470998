import { cn } from '@vectroid/shared/utils'
import { Button } from 'components/catalyst/button'
import { Divider } from 'components/catalyst/divider'
import { useNotificationStore } from 'components/common'
import { CommonTooltip } from 'components/common/tooltip/tooltip'
import { FileUploader } from 'components/file-upload/file-uploader'
import { useRef, useState } from 'react'
import { UploadStatus } from '../const/upload-status.enum'
import { useDatasetDetailStore } from '../store/datase-detail.store'
import { useDatasetBulkUploadStore } from '../store/dataset-bulk-upload.store'
import { initDatasetBulkUploadSession } from '../utils'

type Props = {
  closeDialog?: () => void
}

export function DatasetBulkUpload({ closeDialog }: Props) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const startUpload = useDatasetBulkUploadStore((state) => state.startUpload)
  const setDataset = useDatasetBulkUploadStore((state) => state.setDataset)
  const setUploadId = useDatasetBulkUploadStore((state) => state.setUploadId)
  const uploadStatus = useDatasetBulkUploadStore((state) => state.uploadStatus)
  const { dataset } = useDatasetDetailStore()
  const { setMessage } = useNotificationStore()

  const isUploading = uploadStatus === UploadStatus.Uploading
  const uploaderRef = useRef<{ reset: () => void } | null>(null)

  function onFileSelected(files: FileList) {
    const file = files[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  async function handleStartUpload() {
    if (!selectedFile || !dataset) return

    // Files larger than 20MB are uploaded using the resumable protocol.
    const isResumable = selectedFile.size > 20 * 1024 * 1024

    let bulkUploadSession
    try {
      bulkUploadSession = await initDatasetBulkUploadSession(dataset.uri, dataset.name, isResumable, selectedFile.name)
    } catch (err) {
      setMessage('Failed to initialize bulk upload session.', { type: 'error' })
      return
    }

    if (!bulkUploadSession?.signedUrl) {
      setMessage('Failed to get signed URL.', { type: 'error' })
      return
    }

    // Set the dataset and upload ID in state
    setDataset(dataset)
    setUploadId(bulkUploadSession.uploadId)

    // Start the upload asynchronously.
    startUpload(bulkUploadSession.signedUrl, selectedFile, isResumable).catch((error) => {
      setMessage('Upload failed. Please try again.', { type: 'error' })
      console.error('Upload failed:', error)
    })

    // Close the modal/dialog.
    closeDialog?.()
    handleReset()
  }

  function handleReset() {
    setSelectedFile(null)
    uploaderRef.current?.reset()
  }

  return (
    <div className="space-y-6">
      <CommonTooltip
        className="w-full"
        enabled={isUploading}
        content="Please wait until the current upload is completed."
      >
        <div className={cn(isUploading && 'not-allowed:opacity-50 pointer-events-none w-full')}>
          <FileUploader ref={uploaderRef} onFileSelected={onFileSelected} fileType=".zip, .gzip, .tar.gz, .hdf5" />
        </div>
      </CommonTooltip>

      {selectedFile && (
        <>
          <Divider soft />

          <div className="flex justify-end space-x-4">
            <Button outline onClick={closeDialog ? closeDialog : handleReset}>
              Cancel
            </Button>
            <Button color="amber" onClick={handleStartUpload}>
              Start Uploading
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
