import { initDatasetBulkUploadSessionApi } from '../services'
import { getDatasetURL } from './get-dataset-url'
import { initiateResumableSession } from './upload-utils'

interface BulkUploadSession {
  signedUrl: string
  uploadId: string
}

/**
 * Get a signed URL for uploading a file to GCS.
 *
 * @param datasetUri {string} - The URI of the dataset to upload to.
 * @param datasetName {string} - The name of the dataset to upload to.
 * @param resumable {boolean} - Optional. Whether the upload should be resumable.
 * @param filename {string} - Optional. The name of the file to upload.
 * @returns {Promise<string>}
 */
export async function initDatasetBulkUploadSession(
  datasetUri: string,
  datasetName: string,
  resumable: boolean,
  fileName?: string
): Promise<BulkUploadSession> {
  try {
    const response = await initDatasetBulkUploadSessionApi(getDatasetURL(datasetUri), datasetName, {
      resumable,
      fileName,
    })
    let { signedUrl, uploadId, resumableSessionInfo } = response.data
    if (resumableSessionInfo) {
      signedUrl = await initiateResumableSession(signedUrl, resumableSessionInfo)
    }

    return {
      signedUrl,
      uploadId,
    }
  } catch (e) {
    console.log(e)
    throw new Error('Failed to get signed URL.')
  }
}
