export const ShellCurlSnippet = {
  POST: `curl --request POST \\
  --url {{url}} \\
  --header 'Accept: application/json' \\
  --header 'x-api-key: {{apiKey}}' \\
  --header 'Content-Type: application/json' \\
  --data '{{body}}'`,

  GET: `curl --request GET \\
  --url {{url}} \\
  --header 'Accept: application/json' \\
  --header 'x-api-key: {{apiKey}}'`,

  PUT: `curl --request PUT \\
  --url {{url}} \\
  --header 'Accept: application/json' \\
  --header 'x-api-key: {{apiKey}}' \\
  --header 'Content-Type: application/json' \\
  --data '{{body}}'`,

  DELETE: `curl --request DELETE \\
  --url {{url}} \\
  --header 'Accept: application/json' \\
  --header 'x-api-key: {{apiKey}}'`,
}

export const ShellHTTPieSnippet = {
  POST: `echo '{{body}}' | \\
  http POST {{url}} \\
  Accept:application/json \\
  x-api-key:'{{apiKey}}' \\
  Content-Type:application/json`,

  GET: `http GET {{url}} \\
  Accept:application/json \\
  x-api-key:'{{apiKey}}'`,

  PUT: `echo '{{body}}' | \\
  http PUT {{url}} \\
  Accept:application/json \\
  x-api-key:'{{apiKey}}' \\
  Content-Type:application/json`,

  DELETE: `http DELETE {{url}} \\
  Accept:application/json \\
  x-api-key:'{{apiKey}}'`,
}

export const ShellWgetSnippet = {
  POST: `wget --quiet \\
  --method POST \\
  --header 'x-api-key: {{apiKey}}' \\
  --header 'Accept: application/json' \\
  --header 'Content-Type: application/json' \\
  --body-data '{{body}}' \\
  --output-document \\
  - {{url}}`,

  GET: `wget --quiet \\
  --method GET \\
  --header 'x-api-key: {{apiKey}}' \\
  --header 'Accept: application/json' \\
  --output-document \\
  - {{url}}`,

  PUT: `wget --quiet \\
  --method PUT \\
  --header 'x-api-key: {{apiKey}}' \\
  --header 'Accept: application/json' \\
  --header 'Content-Type: application/json' \\
  --body-data '{{body}}' \\
  --output-document \\
  - {{url}}`,

  DELETE: `wget --quiet \\
  --method DELETE \\
  --header 'x-api-key: {{apiKey}}' \\
  --header 'Accept: application/json' \\
  --output-document \\
  - {{url}}`,
}
