import { ReactNode } from 'react'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'
import { Text } from './catalyst/text'

export function BetaFeature(props: {
  children: ReactNode
  enabled?: boolean
  position?: 'bottom' | 'top' | 'right' | 'left'
}) {
  const { enabled = false } = props

  if (enabled) {
    return <>{props.children}</>
  }

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger className="cursor-not-allowed opacity-80">
          <div className="pointer-events-none">{props.children}</div>
        </TooltipTrigger>
        <TooltipContent side={props.position ?? 'bottom'}>
          <Text>Coming soon..</Text>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
