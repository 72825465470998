import { RouteNames } from '@vectroid/shared/const'
import { DropdownItem, DropdownLabel, DropdownMenu } from 'components/catalyst/dropdown'
import { LogOut as ArrowRightStartOnRectangleIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export function AccountDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {
  const navigate = useNavigate()

  function handleLogout() {
    navigate(RouteNames.Logout)
  }

  return (
    <DropdownMenu className="min-w-64" anchor={anchor}>
      <DropdownItem onClick={handleLogout}>
        <ArrowRightStartOnRectangleIcon size={16} className="mr-3" />
        <DropdownLabel>Sign out</DropdownLabel>
      </DropdownItem>
    </DropdownMenu>
  )
}
