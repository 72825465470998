import { cn } from '@vectroid/shared/utils'
import React from 'react'

interface UploadProgressBarProps {
  uploadProgress: number
  isMinimized: boolean
  isCompleted: boolean
}

export const UploadProgressBar: React.FC<UploadProgressBarProps> = ({ uploadProgress, isMinimized, isCompleted }) => {
  return (
    <div className="absolute bottom-0 left-0 right-0">
      <div className={cn('bg-gray-200 dark:bg-zinc-600', isMinimized ? 'h-[2px]' : 'h-1')}>
        <div
          className={cn(
            'transition-all ease-in-out',
            isMinimized ? 'h-[2px]' : 'h-1',
            isCompleted ? 'bg-green-600' : 'bg-amber-500'
          )}
          style={{ width: `${uploadProgress}%` }}
        />
      </div>
    </div>
  )
}
