import { localAppConfig } from '../const/local.app-config'

export interface AppConfig {
  BASE_URL: string
  API_BASE_URL: string
  CONSOLE_BASE_URL: string
  DOCS_BASE_URL: string
  API_PREFIX: string
  GOOGLE_OAUTH_CLIENT_ID: string
}

const appConfig = new Map<keyof AppConfig, string>()

export function setAppConfig(config: AppConfig) {
  Object.entries(config).forEach(([key, value]) => {
    appConfig.set(key as keyof AppConfig, value)
  })
}

export { appConfig, localAppConfig }
