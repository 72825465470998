import { FacKeys, isFeatureEnabled } from '@vectroid/shared/config'
import { RouteNames } from '@vectroid/shared/const'
import { BetaFeature } from 'components/beta-feature'
import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { Text } from 'components/catalyst/text'
import { TabItem } from 'hooks/use-tabs'
import { DatasetBadgeButton } from 'modules/datasets/components/dataset-badge-button'
import { ShareResourceDialog } from 'modules/datasets/components/share-resource-dialog'
import { useLocation } from 'react-router-dom'
import { useDatasetDetailStore } from '../store/datase-detail.store'
import { DatasetBulkUploadDialog } from './dataset-bulk-upload-dialog'

export function DatasetDetailHeader() {
  const dataset = useDatasetDetailStore((state) => state.dataset)!
  const { pathname } = useLocation()

  const DatasetDetailTabs: TabItem[] = [
    { label: 'Overview', href: RouteNames.DatasetDetailOverview.name },
    {
      label: 'Bulk Uploads',
      href: RouteNames.DatasetDetailBulkUploads.name,
      enabled: isFeatureEnabled(FacKeys.BulkUpload),
    },
  ].map((tab) => ({ ...tab, isPageTab: true }))

  const tabs = DatasetDetailTabs.map((tab) => ({
    ...tab,
    current: pathname.includes(tab.href),
  }))

  return (
    <Heading
      pageHeading
      breadcrumb={{ label: 'Dataset', path: RouteNames.Datasets }}
      tabs={tabs}
      actions={
        <>
          <BetaFeature>
            <ShareResourceDialog resourceName={dataset.name} resourceType="Dataset" />
          </BetaFeature>

          <DatasetBulkUploadDialog />

          <Button href={`${RouteNames.Indexes}?create=true&dataset=${dataset.name}`} color="amber">
            Create New Index
          </Button>
        </>
      }
    >
      <div className="flex items-center space-x-3">
        <DatasetBadgeButton dataset={dataset} className="pointer-events-none" />
        <Text className="mt-0.5 font-medium">#{dataset.id}</Text>
      </div>
    </Heading>
  )
}
