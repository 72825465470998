export function formatTimeRemaining(seconds: number): string {
  if (seconds < 60) {
    return 'Less than a minute'
  }
  const minutes = Math.floor(seconds / 60)
  if (minutes === 1) {
    return '1 minute'
  }
  if (minutes < 60) {
    return `${minutes} minutes`
  }
  const hours = Math.floor(minutes / 60)
  return `${hours} hr ${minutes % 60} min`
}
