import { AxiosResponse } from 'axios'
import { useNotificationStore } from 'components/common'
import { useCallback, useState } from 'react'

interface UseAsyncDataReturn<T> {
  data: T | null
  loading: boolean
  fetchData: () => Promise<void>
}

interface Options<T, K> {
  onDataFetched?: (data: T) => void
  params?: K
  showErrorMessage?: boolean
}

export function useAsyncData<T, K extends any[]>(
  apiFunction: (...params: K) => Promise<AxiosResponse<T>>,
  options?: Options<T, K>
): UseAsyncDataReturn<T> {
  const [loading, setLoading] = useState(false)
  const { reset, setMessage } = useNotificationStore()
  const [data, setData] = useState<T | null>(null)

  const fetchData = useCallback(async () => {
    setLoading(true)
    reset()
    try {
      const response = await apiFunction(...(options?.params ?? ([] as unknown as K)))
      setData(response.data)
      options?.onDataFetched?.(response.data)
    } catch (error: any) {
      options?.showErrorMessage &&
        setMessage(error?.response?.data?.message, {
          title: error?.response?.data?.code,
          type: 'error',
          actionBtns: [{ label: 'Retry', onClick: fetchData }],
        })
    } finally {
      setLoading(false)
    }
  }, [apiFunction, reset, setMessage, options])

  return { data, loading, fetchData }
}
