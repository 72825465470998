import { Text } from 'components/catalyst/text'
import { useTabs } from 'hooks/use-tabs'
import { useDatasetStore } from 'modules/datasets'
import { useEffect } from 'react'
import { IndexesHeader } from '../components/indexes-header'
import { IndexesHeaderToolbar } from '../components/indexes-header-toolbar'
import { IndexListView } from '../containers/index-list-view'
import { useIndexStore } from '../store'

enum Tabs {
  MyIndexes = 'my-indexes',
  SharedWithMe = 'shared-with-me',
}

function IndexesPage() {
  const { indexes, loading: indexesLoading, fetchIndexes } = useIndexStore()
  const { datasets, loading: datasetsLoading, fetchDatasets } = useDatasetStore()

  const { tabs, currentTab } = useTabs([
    {
      label: 'My Indexes',
      href: Tabs.MyIndexes,
    },
    {
      label: 'Shared With Me',
      href: Tabs.SharedWithMe,
      enabled: false,
    },
  ])

  useEffect(() => {
    fetchIndexes()
    fetchDatasets()
  }, [])

  const loading = indexesLoading || datasetsLoading

  return (
    <>
      <IndexesHeader tabs={tabs} />
      <IndexesHeaderToolbar />
      {currentTab === Tabs.MyIndexes && <IndexListView datasets={datasets} indexes={indexes} loading={loading} />}
      {currentTab === Tabs.SharedWithMe && <Text>No resources has been shared with you yet.</Text>}
    </>
  )
}

export default IndexesPage
