import { Collection, GLOBAL_API_PLAYGROUND_COLLECTION } from '@vectroid/shared/api-collections'
import { Dataset } from 'modules/datasets'
import { create } from 'zustand'

type State = {
  selectedDataset: Dataset | null
  activeCollection: Collection
  activeCollectionAPIBaseURL: string
}

type Actions = {
  setSelectedDataset: (dataset: Dataset | null) => void
  setActiveCollection: (collection: Collection) => void
  setActiveCollectionAPIBaseURL: (url: string) => void
}

export const useCollectionStore = create<State & Actions>((set) => ({
  selectedDataset: null,
  activeCollection: GLOBAL_API_PLAYGROUND_COLLECTION[0].items[0],
  activeCollectionAPIBaseURL: '',
  setActiveCollectionAPIBaseURL: (url: string) => set({ activeCollectionAPIBaseURL: url }),
  setActiveCollection: (collection: Collection) => set({ activeCollection: collection }),
  setSelectedDataset: (dataset: Dataset | null) => set({ selectedDataset: dataset }),
}))
