import { cn } from '@vectroid/shared/utils'
import { Card } from 'components/catalyst/card'
import { CommonTooltip } from 'components/common/tooltip/tooltip'
import { DatasetBadgeButton } from 'modules/datasets/components/dataset-badge-button'
import { useContext } from 'react'
import { IndexDetailContext } from '../context'
import { IndexSizeProgress } from './index-size-progress'

export function IndexOverview() {
  const { indexDetail, datasetDetail } = useContext(IndexDetailContext)

  const items = [
    {
      name: 'Indexing Progress',
      value: <IndexSizeProgress datasetUri={datasetDetail.uri} indexName={indexDetail.name} />,
    },
    {
      name: 'Source Dataset',
      value: <DatasetBadgeButton dataset={datasetDetail} />,
    },
    {
      name: 'DIMENSION',
      value: <span className="block">{datasetDetail.dimensions}</span>,
    },
    {
      name: 'HNSW Parameters',
      value: (
        <CommonTooltip
          content={
            <>
              <span className="flex space-x-2">
                <span>m:</span>
                <b>{indexDetail?.configuration?.hnswConfig?.hnswConnections}</b>
              </span>
              <span className="flex space-x-2">
                <span>ef_construction:</span>
                <b>{indexDetail?.configuration?.hnswConfig?.hnswBeamWidth}</b>
              </span>
            </>
          }
        >
          <span className="-m-2 rounded-lg p-2 transition-all hover:bg-zinc-100 hover:dark:bg-zinc-900">
            {indexDetail?.configuration?.hnswConfig?.hnswConnections} /{' '}
            {indexDetail?.configuration?.hnswConfig?.hnswBeamWidth}
          </span>
        </CommonTooltip>
      ),
    },
    {
      name: 'Similarity',
      value: <span className="block">{indexDetail.similarityFunction}</span>,
    },
  ]

  return (
    <Card className="mx-auto mb-6 p-0">
      <div className="-mb-px lg:flex grid grid-cols-12">
        {items.map((item: any) => (
          <div
            key={item.name}
            className={cn(
              'col-span-6 flex-1 border-r border-zinc-200 p-6 dark:border-zinc-800',
              items.indexOf(item) >= items.length - 2 ? '' : 'border-b',
              item.className
            )}
          >
            <dt className="whitespace-nowrap text-[12px] font-semibold uppercase text-zinc-500">{item.name}</dt>
            <dd className="mt-2 text-sm font-medium tracking-tight">{item.value}</dd>
          </div>
        ))}
      </div>
    </Card>
  )
}
