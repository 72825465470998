import { ArrowLeftIcon } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

interface BreadcrumbProps {
  breadcrumb: { label: string; path: string }
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb }) => {
  return (
    <Link to={breadcrumb.path} className="flex items-center pt-0.5">
      <ArrowLeftIcon className="mr-2 size-4" />
      {breadcrumb.label}
    </Link>
  )
}

export default Breadcrumb
