import { ApiDocsButton } from 'components/api-docs-button'
import { Heading } from 'components/catalyst/heading'
import { HardDriveUpload } from 'lucide-react'
import { DatasetBulkUpload } from './dataset-bulk-upload'

export function DatasetBulkUploadsEmptyState() {
  return (
    <>
      <Heading
        actions={
          <>
            <ApiDocsButton path="/dataset-insert" />
          </>
        }
        className="!text-[18px]"
        level={3}
      >
        <div className="flex items-center space-x-2">
          <HardDriveUpload size={18} />
          <span>Upload your dataset</span>
        </div>
      </Heading>

      <DatasetBulkUpload />
    </>
  )
}
