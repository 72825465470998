import { formatBytes } from '@vectroid/shared/utils'
import { Divider } from 'components/catalyst/divider'
import { Text } from 'components/catalyst/text'
import { FileIcon } from 'lucide-react'
import { Dataset } from 'modules/datasets'
import { DatasetBadgeButton } from 'modules/datasets/components/dataset-badge-button'
import React from 'react'

interface UploadProgressContentProps {
  currentFile: File
  isCompleted: boolean
  bytesUploaded: number
  dataset: Dataset | null
}

export const UploadProgressContent: React.FC<UploadProgressContentProps> = ({
  currentFile,
  isCompleted,
  bytesUploaded,
  dataset,
}) => {
  return (
    <>
      <div className="flex items-start p-4">
        <div className="mr-3 rounded-md bg-gray-100 p-2 text-gray-600 dark:bg-zinc-700 dark:text-zinc-100">
          <FileIcon size={24} />
        </div>
        <div className="flex-1">
          <Text className="font-medium text-gray-900 dark:text-zinc-100">{currentFile.name}</Text>
          {isCompleted ? (
            <Text className="text-sm text-green-600 dark:text-green-400">File uploaded successfully</Text>
          ) : (
            <>
              <Text className="text-sm text-gray-500 dark:text-zinc-300">{currentFile.type || 'Unknown type'}</Text>
              <Text className="text-xs text-gray-500 dark:text-zinc-300">
                {formatBytes(bytesUploaded)} of {formatBytes(currentFile.size)} uploaded
              </Text>
            </>
          )}
        </div>
      </div>
      {dataset && (
        <>
          <Divider />
          <div className="flex items-start space-x-2 p-4">
            <Text>Dataset:</Text>
            <DatasetBadgeButton dataset={dataset} />
          </div>
        </>
      )}
    </>
  )
}
