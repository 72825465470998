import { useEffect } from 'react'

import { Badge } from 'components/catalyst/badge'
import { ColumnDefinition, CommonTable } from 'components/common'
import { CopyButton } from 'components/copy-button'
import { useAsyncData } from 'hooks/use-async-data'

import { listApiKeysApi } from '../services/api-key.services'
import { ApiKey } from '../types'

export function ApiKeysTable() {
  const { loading, data, fetchData } = useAsyncData(listApiKeysApi, {
    showErrorMessage: true,
  })

  useEffect(() => {
    fetchData()
  }, [])

  const getApiKeyDisplayText = (key: string) => `${key?.slice(0, 6)}${'*'.repeat(26)}`

  const columns: ColumnDefinition<ApiKey>[] = [
    { key: 'id', header: 'ID' },
    { key: 'name', header: 'NAME' },
    {
      key: 'apiKey',
      header: 'API KEY',
      cell: (row) => (
        <div className="flex space-x-4">
          <Badge className="px-3">
            <code>{getApiKeyDisplayText(row.key)}</code>
          </Badge>
          <CopyButton text={row.key} />
        </div>
      ),
    },
  ]

  return <CommonTable<ApiKey> columns={columns} data={data?.apiKeys ?? []} loading={loading} />
}
