import { Listbox, ListboxOption } from 'components/catalyst/listbox'
import { useDatasetStore } from 'modules/datasets/store/dataset.store'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Dataset } from '../types'

export enum DatasetSelectType {
  MyDatasets = 'my-datasets',
  SharedWithMe = 'shared-with-me',
  PublicDatasets = 'public-datasets',
}

type DatasetSelectComboBoxProps = {
  value?: string | null
  onChange: (dataset: Dataset | null) => void
  initialSelectType?: DatasetSelectType
  disabled?: boolean
  showDatasetTypeSelector?: boolean
}

export function DatasetSelectComboBox({
  value,
  onChange,
  initialSelectType = DatasetSelectType.MyDatasets,
  disabled,
  showDatasetTypeSelector = true,
}: DatasetSelectComboBoxProps) {
  const { datasets, loading, fetchDatasets } = useDatasetStore()
  const [selectType, setSelectType] = useState<DatasetSelectType>(initialSelectType)
  const [searchParams] = useSearchParams()

  const datasetNameFromSearchParams = searchParams.get('dataset') || ''

  const getOptions = (): Dataset[] => {
    switch (selectType) {
      case DatasetSelectType.MyDatasets:
        return datasets
      case DatasetSelectType.PublicDatasets:
        return []
      case DatasetSelectType.SharedWithMe:
        return [] // Add logic if needed
      default:
        return []
    }
  }

  const options = getOptions()

  useEffect(() => {
    fetchDatasets()
  }, [])

  useEffect(() => {
    onChange(null)
  }, [selectType])

  useEffect(() => {
    if (datasetNameFromSearchParams) {
      const selectedDataset = options.find((dataset) => dataset.name === datasetNameFromSearchParams)
      if (selectedDataset) {
        onChange(selectedDataset)
      }
    }
  }, [options, datasetNameFromSearchParams])

  const typeSelectOptions = [
    { label: 'My Datasets', value: DatasetSelectType.MyDatasets },
    { label: 'Shared With Me', value: DatasetSelectType.SharedWithMe, disabled: true },
    { label: 'Public Datasets', value: DatasetSelectType.PublicDatasets, disabled: true },
  ]

  return (
    <div className={`grid ${showDatasetTypeSelector ? 'grid-cols-12 space-x-4' : ''}`}>
      {showDatasetTypeSelector && (
        <Listbox
          defaultValue={selectType}
          className="col-span-4 !rounded-r-none"
          onChange={(val) => setSelectType(val)}
          disabled={disabled}
        >
          {typeSelectOptions.map((option) => (
            <ListboxOption key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </ListboxOption>
          ))}
        </Listbox>
      )}

      <Listbox
        className={showDatasetTypeSelector ? 'col-span-8' : 'w-full'}
        onChange={(val) => {
          const selected = options.find((d) => d.name === val)
          onChange(selected ?? null)
        }}
        value={value ?? ''}
        disabled={disabled}
        placeholder="Select from the list"
      >
        {options.map((dataset) => (
          <ListboxOption value={dataset.name} key={dataset.name}>
            {dataset.name}
          </ListboxOption>
        ))}
        {loading && (
          <ListboxOption value="" disabled>
            Loading...
          </ListboxOption>
        )}
        {!loading && options.length === 0 && (
          <ListboxOption value="" disabled>
            No resources available
          </ListboxOption>
        )}
      </Listbox>
    </div>
  )
}
