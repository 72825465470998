import { appConfig } from '@vectroid/shared/config'
import { useDocsPanel } from 'context/docs-provider'
import { useTheme } from 'context/theme-provider'
import { XIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Button } from './catalyst/button'

export const getDocsUrl = (path: string, theme: string) => {
  const url = new URL(`${appConfig.get('DOCS_BASE_URL')}${path}/`)
  url.searchParams.set('theme', theme)

  return url.toString()
}

export function DocsPanel() {
  const { theme } = useTheme()
  const { setVisible, path } = useDocsPanel()
  const [iframeUrl, setIframeUrl] = useState(getDocsUrl(path, theme))
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const url = getDocsUrl(path, theme)
    if (iframeRef.current && iframeRef.current.src !== url) {
      setIframeUrl(url)
    }
  }, [path, theme])

  return (
    <>
      <div className="absolute right-2 top-2.5 z-30 rounded-lg bg-white dark:bg-zinc-950">
        <Button outline onClick={() => setVisible(false)} className="dark:bg-zinc-950">
          <XIcon size={16} />
          Close
        </Button>
      </div>

      <iframe ref={iframeRef} src={iframeUrl} className="h-full w-full rounded-2xl" title="Documentation" />
    </>
  )
}
