import * as Headless from '@headlessui/react'
import { useAuthStore } from '@vectroid/shared/auth'
import { cn } from '@vectroid/shared/utils'
import { Divider } from 'components/catalyst/divider'
import { Dropdown, DropdownButton } from 'components/catalyst/dropdown'
import { NavbarItem } from 'components/catalyst/navbar'
import { SidebarItem } from 'components/catalyst/sidebar'
import { CommandBar } from 'components/command-bar'
import { DocsPanel } from 'components/docs-panel'
import { FeedbackWidget } from 'components/feedback/feedback-widget'
import { ThemeToggle } from 'components/theme-toggle'
import { useDocsPanel } from 'context/docs-provider'
import { useTheme } from 'context/theme-provider'
import { ChevronDownIcon } from 'lucide-react'
import React from 'react'
import { AccountDropdownMenu } from './account-dropdown'
import { OpenMenuIcon } from './icons'
import { MobileSidebar } from './mobile-sidebar'

export function SidebarLayout({
  navbar,
  sidebar,
  children,
}: React.PropsWithChildren<{ navbar: React.ReactNode; sidebar: React.ReactNode }>) {
  const [showSidebar, setShowSidebar] = React.useState(false)
  const account = useAuthStore((state) => state.account)
  const { visible: isDocsPanelVisible, setVisible } = useDocsPanel()

  const hideDocsPanel = () => setVisible(false)

  const { sidebarExpanded } = useTheme()

  return (
    <div
      className={cn(
        'relative isolate flex min-h-svh w-full bg-white dark:bg-zinc-900 max-lg:flex-col lg:bg-white dark:lg:bg-zinc-950',
        isDocsPanelVisible && 'max-lg:pointer-events-none'
      )}
    >
      <div
        className={cn(
          'fixed inset-y-0 left-0 z-20 transition-all dark:bg-zinc-950 max-lg:hidden',
          sidebarExpanded ? 'w-52' : 'w-20',
          isDocsPanelVisible && 'lg:pointer-events-none'
        )}
      >
        {sidebar}
      </div>

      <Headless.Transition show={isDocsPanelVisible} as={React.Fragment}>
        <Headless.Dialog onClose={hideDocsPanel || (() => {})} className="relative z-40">
          <Headless.TransitionChild
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" />
          </Headless.TransitionChild>
          <div className="fixed inset-0 flex justify-end">
            <Headless.TransitionChild
              as={React.Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-400"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-400"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Headless.DialogPanel
                className={cn(
                  'relative flex h-full w-full flex-col overflow-y-auto bg-white shadow-2xl dark:bg-zinc-900',
                  'lg:w-[520px]',
                  'pointer-events-auto'
                )}
              >
                <DocsPanel />
              </Headless.DialogPanel>
            </Headless.TransitionChild>
          </div>
        </Headless.Dialog>
      </Headless.Transition>

      <MobileSidebar open={showSidebar} close={() => setShowSidebar(false)}>
        {sidebar}
      </MobileSidebar>

      <header className="flex items-center px-4 lg:hidden">
        <div className="pointer-events-auto py-2.5">
          <NavbarItem onClick={() => setShowSidebar(true)} aria-label="Open navigation">
            <OpenMenuIcon />
          </NavbarItem>
        </div>
        <div className="min-w-0 flex-1">{navbar}</div>
      </header>

      <main
        className={cn(
          'flex flex-1 flex-col transition-all lg:min-w-0 lg:pt-3',
          sidebarExpanded ? 'lg:pl-52' : 'lg:pl-20',
          isDocsPanelVisible && 'max-lg:hidden lg:pointer-events-none'
        )}
      >
        <div className={cn('mb-3 hidden items-center space-x-3 px-4 lg:flex')}>
          <span className={'flex-1'} />
          <CommandBar />
          <FeedbackWidget />
          <ThemeToggle />
          <Dropdown>
            <DropdownButton as={SidebarItem} href="#" className="!p-0 text-left">
              <span className="flex min-w-0 items-center gap-3 px-1">
                <span className="min-w-0">
                  <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                    {account?.name}
                  </span>
                  <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                    {account?.email}
                  </span>
                </span>
              </span>
              <ChevronDownIcon size={16} />
            </DropdownButton>
            <AccountDropdownMenu anchor="top start" />
          </Dropdown>
        </div>

        <Divider />
        <div
          className={cn('!lg:px-6 grow overflow-y-scroll bg-zinc-200/40 p-6 dark:bg-zinc-800/30')}
          id="application-main"
        >
          <div className="mx-auto flex min-h-full flex-col">{children}</div>
        </div>
      </main>
    </div>
  )
}
