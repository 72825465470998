import { isEscapeCharBodyLang, replaceStaticVariables } from '@vectroid/shared/api-collections'
import { useAuthStore } from '@vectroid/shared/auth'
import { appConfig } from '@vectroid/shared/config'
import { CodeSnippetLanguages, Snippets } from '@vectroid/shared/snippets'
import { useUserDefaultsStore } from '@vectroid/shared/store'
import { useCollectionStore } from 'modules/indexes'
import { useEffect, useState } from 'react'

export function useCodeGenerator(bodyObject: string, baseURL?: string) {
  const preferredCodeLang = useUserDefaultsStore((state) => state.preferredCodeLang)
  const selectedSnippet = CodeSnippetLanguages.find((c) => c.id === preferredCodeLang)
  const [generatedCode, setGeneratedCode] = useState('')

  const { account } = useAuthStore()
  const { activeCollection } = useCollectionStore()

  const handleSnippetChange = () => {
    if (!selectedSnippet) {
      return
    }

    try {
      let parsedBody = '{}'
      if (activeCollection.method !== 'GET' && bodyObject) {
        parsedBody = JSON.stringify(JSON.parse(bodyObject))
      }

      const baseUrl = baseURL ?? appConfig.get('BASE_URL')
      const url = `${baseUrl}${activeCollection?.endpoint}`
      const snippet = Snippets[selectedSnippet.id]
      // @ts-ignore
      const codeSnippet = replaceStaticVariables(snippet[activeCollection.method], {
        url,
        apiKey: '<YOUR API KEY>',
        body: isEscapeCharBodyLang(selectedSnippet.id) ? parsedBody.replaceAll('"', '\\"') : parsedBody,
        endpoint: activeCollection?.endpoint,
        host: `${account?.endpoint}.api.vectroid.com`,
        contentLength: parsedBody.length.toString(),
        method: activeCollection.method,
        baseUrl,
      })
      setGeneratedCode(codeSnippet)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (selectedSnippet) {
      handleSnippetChange()
    }
  }, [selectedSnippet, bodyObject])

  return {
    handleSnippetChange,
    generatedCode,
    selectedSnippet,
  }
}
