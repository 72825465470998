import { useAsyncData } from 'hooks/use-async-data'
import { Dataset } from 'modules/datasets'
import { Index } from 'modules/indexes'
import { create } from 'zustand'
import { describeIndexApi } from '../services'

interface IndexDetailState {
  index: Index | null
  dataset: Dataset | null
  setIndex: (index: Index | null) => void
  setDataset: (dataset: Dataset | null) => void
}

export const useIndexDetailStore = create<IndexDetailState>((set) => ({
  index: null,
  dataset: null,
  setIndex: (index) => set({ index }),
  setDataset: (dataset) => set({ dataset }),
}))

export function useIndexDetail(indexName: string) {
  const { index, setIndex, dataset, setDataset } = useIndexDetailStore()

  const { loading: indexLoading, fetchData: describeIndex } = useAsyncData(describeIndexApi, {
    onDataFetched: (fetchedIndex) => {
      setIndex(fetchedIndex?.index)
      setDataset(fetchedIndex?.dataset)
    },
    params: [indexName],
    showErrorMessage: true,
  })

  return {
    index,
    dataset,
    indexLoading,
    describeIndex,
  }
}
