import { cn } from '@vectroid/shared/utils'
import React from 'react'

interface SpinnerProps {
  className?: string
  color?: string
  children?: React.ReactNode
}

const Spinner: React.FC<SpinnerProps> = ({ children, className = 'w-10 h-10', color = 'border-t-current' }) => {
  return (
    <div className={'flex items-center justify-center'}>
      <div
        className={cn('animate-spin rounded-full border-2 border-x-transparent border-b-transparent', color, className)}
      />
      {children ? <div className="ml-4">{children}</div> : null}
    </div>
  )
}

export default Spinner
