import { ColumnDefinition, CommonTable } from 'components/common'
import { useSearchParamFilteredArray } from 'hooks/use-search-query-param'
import { DatasetBadgeButton } from 'modules/datasets/components/dataset-badge-button'
import { IndexWithDataset } from '../types'
import { IndexBadgeButton } from './index-badge-button'
import { IndexSizeProgress } from './index-size-progress'

type Props = {
  loading: boolean
  indexes: IndexWithDataset[]
}

export function IndexesStackLayout(props: Props) {
  const filteredIndexes = useSearchParamFilteredArray(props.indexes, { searchKeys: ['name'] })

  const columns: ColumnDefinition<IndexWithDataset>[] = [
    { key: 'id', header: 'ID' },
    {
      key: 'name',
      header: 'Index Name',
      primary: true,
      cell: (row) => <IndexBadgeButton index={row} />,
    },
    {
      key: 'indexingProgress',
      header: 'Indexing Progress',
      cell: (row) => <IndexSizeProgress datasetUri={row.dataset.uri} indexName={row.name} />,
    },
    {
      key: 'dataset.name',
      header: 'Source Dataset',
      cell: (row) => <DatasetBadgeButton dataset={row.dataset} />,
    },
    { key: 'dimensions', header: 'Dimension', cell: (row) => row.dataset.dimensions },
    { key: 'similarityFunction', header: 'Similarity Function' },
  ]

  return <CommonTable<IndexWithDataset> columns={columns} data={filteredIndexes} loading={props.loading} />
}
