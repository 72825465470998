export const RouteNames = {
  // Main
  Dashboard: '/',
  ApiKeys: '/api-keys',
  ApiPlayground: '/api-playground',

  // Getting Staretd
  GettingStarted: '/getting-started',

  // Auth
  Login: '/login',
  Register: '/register',
  ForgotPassword: '/forgot-password',
  VerifyAccount: '/verify-account',
  GoogleSSOCallback: '/oauth2/callback',
  Logout: '/logout',

  // Index
  Indexes: '/index',
  CreateIndex: '/index/create',
  IndexDetail: {
    get: (indexName: string) => `/index/${indexName}`,
    name: '/index/:indexName',
  },
  IndexDetailConsole: {
    get: (indexName: string, action?: string) => `/index/${indexName}/playground${action ? `?action=${action}` : ''}`,
    name: 'playground',
  },
  IndexDetailAccessDB: {
    get: (indexName: string) => `/index/${indexName}/access-db`,
    name: 'access-db',
  },
  IndexDetailSettings: {
    get: (indexName: string) => `/index/${indexName}/settings`,
    name: 'settings',
  },

  // Dataset
  Datasets: '/dataset',
  CreateDataset: '/dataset/create',
  DatasetDetail: {
    get: (datasetName: string) => `/dataset/${datasetName}`,
    name: '/dataset/:datasetName',
  },
  DatasetDetailOverview: {
    get: (datasetName: string) => `/dataset/${datasetName}/overview`,
    name: 'overview',
  },
  DatasetDetailBulkUploads: {
    get: (datasetName: string) => `/dataset/${datasetName}/bulk-uploads`,
    name: 'bulk-uploads',
  },

  // Not Found
  NotFound: '/404',
}

export const AUTH_ROUTES = [
  RouteNames.Login,
  RouteNames.Register,
  RouteNames.ForgotPassword,
  RouteNames.VerifyAccount,
  RouteNames.GoogleSSOCallback,
]
