import { RouteNames } from '@vectroid/shared/const'
import { Badge, BadgeButton } from 'components/catalyst/badge'
import { CommonTooltip } from 'components/common/tooltip/tooltip'
import { FolderOpenDot } from 'lucide-react'
import { Dataset, DatasetStatus } from '..'
import { getDatasetStatusBadgeColor } from '../utils'

type Props = {
  dataset: Dataset
  className?: string
}

export function DatasetBadgeButton({ dataset, className }: Props) {
  return (
    <CommonTooltip content={`Status: ${dataset.status}`} enabled={dataset.status !== DatasetStatus.ACTIVE}>
      <BadgeButton color={'yellow'} href={RouteNames.DatasetDetail.get(dataset.name)} className={className}>
        <FolderOpenDot size={16} />
        {dataset.name}
      </BadgeButton>
    </CommonTooltip>
  )
}

export function DatasetStatusBadge({ dataset }: Props) {
  return (
    <Badge color={getDatasetStatusBadgeColor(dataset.status)}>
      {dataset.status === DatasetStatus.PROVISIONING ? (
        <span className="size-3 animate-spin rounded-full border-2 border-x-transparent border-b-transparent border-t-current"></span>
      ) : (
        <span className="block size-1 rounded-full bg-current bg-opacity-100"></span>
      )}
      {dataset.status}
    </Badge>
  )
}
