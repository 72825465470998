export const SwiftNSURLSessionSnippet = {
  POST: `import Foundation

let headers = [
  "x-api-key": "{{apiKey}}",
  "Accept": "application/json",
  "Content-Type": "application/json"
]

let postData = NSData(data: "{{body}}".data(using: String.Encoding.utf8)!)

let request = NSMutableURLRequest(url: NSURL(string: "{{url}}")! as URL,
                                        cachePolicy: .useProtocolCachePolicy,
                                    timeoutInterval: 10.0)
request.httpMethod = "POST"
request.allHTTPHeaderFields = headers
request.httpBody = postData as Data

let session = URLSession.shared
let dataTask = session.dataTask(with: request as URLRequest, completionHandler: { (data, response, error) -> Void in
  if let error = error {
    print(error)
  } else {
    let httpResponse = response as? HTTPURLResponse
    print(httpResponse as Any)
  }
})

dataTask.resume()`,

  GET: `import Foundation

let headers = [
  "x-api-key": "{{apiKey}}",
  "Accept": "application/json"
]

let request = NSMutableURLRequest(url: NSURL(string: "{{url}}")! as URL,
                                        cachePolicy: .useProtocolCachePolicy,
                                    timeoutInterval: 10.0)
request.httpMethod = "GET"
request.allHTTPHeaderFields = headers

let session = URLSession.shared
let dataTask = session.dataTask(with: request as URLRequest, completionHandler: { (data, response, error) -> Void in
  if let error = error {
    print(error)
  } else {
    let httpResponse = response as? HTTPURLResponse
    print(httpResponse as Any)
  }
})

dataTask.resume()`,

  PUT: `import Foundation

let headers = [
  "x-api-key": "{{apiKey}}",
  "Accept": "application/json",
  "Content-Type": "application/json"
]

let postData = NSData(data: "{{body}}".data(using: String.Encoding.utf8)!)

let request = NSMutableURLRequest(url: NSURL(string: "{{url}}")! as URL,
                                        cachePolicy: .useProtocolCachePolicy,
                                    timeoutInterval: 10.0)
request.httpMethod = "PUT"
request.allHTTPHeaderFields = headers
request.httpBody = postData as Data

let session = URLSession.shared
let dataTask = session.dataTask(with: request as URLRequest, completionHandler: { (data, response, error) -> Void in
  if let error = error {
    print(error)
  } else {
    let httpResponse = response as? HTTPURLResponse
    print(httpResponse as Any)
  }
})

dataTask.resume()`,

  DELETE: `import Foundation

let headers = [
  "x-api-key": "{{apiKey}}",
  "Accept": "application/json"
]

let request = NSMutableURLRequest(url: NSURL(string: "{{url}}")! as URL,
                                        cachePolicy: .useProtocolCachePolicy,
                                    timeoutInterval: 10.0)
request.httpMethod = "DELETE"
request.allHTTPHeaderFields = headers

let session = URLSession.shared
let dataTask = session.dataTask(with: request as URLRequest, completionHandler: { (data, response, error) -> Void in
  if let error = error {
    print(error)
  } else {
    let httpResponse = response as? HTTPURLResponse
    print(httpResponse as Any)
  }
})

dataTask.resume()`,
}
