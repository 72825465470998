export const RubyNativeSnippet = {
  POST: `require 'uri'
  require 'net/http'

  url = URI("{{url}}")

  http = Net::HTTP.new(url.host, url.port)
  http.use_ssl = true

  request = Net::HTTP::Post.new(url)
  request["x-api-key"] = '{{apiKey}}'
  request["Accept"] = 'application/json'
  request["Content-Type"] = 'application/json'
  request.body = "{{body}}"

  response = http.request(request)
  puts response.read_body`,

  GET: `require 'uri'
  require 'net/http'

  url = URI("{{url}}")

  http = Net::HTTP.new(url.host, url.port)
  http.use_ssl = true

  request = Net::HTTP::Get.new(url)
  request["x-api-key"] = '{{apiKey}}'
  request["Accept"] = 'application/json'

  response = http.request(request)
  puts response.read_body`,

  PUT: `require 'uri'
  require 'net/http'

  url = URI("{{url}}")

  http = Net::HTTP.new(url.host, url.port)
  http.use_ssl = true

  request = Net::HTTP::Put.new(url)
  request["x-api-key"] = '{{apiKey}}'
  request["Accept"] = 'application/json'
  request["Content-Type"] = 'application/json'
  request.body = "{{body}}"

  response = http.request(request)
  puts response.read_body`,

  DELETE: `require 'uri'
  require 'net/http'

  url = URI("{{url}}")

  http = Net::HTTP.new(url.host, url.port)
  http.use_ssl = true

  request = Net::HTTP::Delete.new(url)
  request["x-api-key"] = '{{apiKey}}'
  request["Accept"] = 'application/json'

  response = http.request(request)
  puts response.read_body`,
}
