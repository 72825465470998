import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type State = {
  datasets: string[];
  indexes: string[];
};

type Actions = {
  addDataset: (name: string) => void;
  addIndex: (name: string) => void;
};

export const recentResourcesStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        datasets: [],
        indexes: [],
        addDataset: (name: string) =>
          set((state) => {
            // Remove any existing entry and add the new one at the front
            const updatedDatasets = [
              name,
              ...state.datasets.filter((item) => item !== name),
            ];
            return { datasets: updatedDatasets.slice(0, 3) }; // Keep only the last 3
          }),
        addIndex: (name: string) =>
          set((state) => {
            // Remove any existing entry and add the new one at the front
            const updatedIndexes = [name, ...state.indexes.filter((item) => item !== name)]
            return { indexes: updatedIndexes.slice(0, 4) } // Keep only the last 4
          }),
      }),
      {
        name: "recent-resources-storage",
      },
    ),
  ),
);

export const useTrackRecentResources = () => {
  const { addDataset, addIndex, datasets, indexes } =
    recentResourcesStore.getState();

  const location = useLocation();

  useEffect(() => {
    const match = location.pathname.match(/^\/(dataset|index)\/([^/]+)/);
    if (match) {
      const [, type, name] = match;
      if (type === "dataset") {
        addDataset(name);
      } else if (type === "index") {
        addIndex(name);
      }
    }
  }, [location.pathname, addDataset, addIndex]);

  return {
    recentDatasetNames: datasets,
    recentIndexNames: indexes,
  };
};
