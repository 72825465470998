import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'

import { useRefreshToken } from '@vectroid/shared/auth'
import { AppConfigProvider } from '@vectroid/shared/config'
import { DocsPanelProvider } from 'context/docs-provider'
import { ThemeProvider } from 'context/theme-provider'
import { useAuthRedirect } from 'hooks'

import ApplicationLayout from 'components/app-layout'
import ErrorBoundaryFallback from 'components/error-boundary'
import { SuspenseLoader } from 'components/suspense-loader'

import { setupAxiosInterceptor } from 'lib'

setupAxiosInterceptor()

export default function Root() {
  useRefreshToken()
  const { isAuthenticated, isAccountVerified } = useAuthRedirect()

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <ThemeProvider defaultTheme="system" storageKey="vectroid-theme">
          <AppConfigProvider>
            <DocsPanelProvider>
              <ApplicationLayout isAuthenticated={isAuthenticated} isAccountVerified={isAccountVerified}>
                <Outlet />
              </ApplicationLayout>
            </DocsPanelProvider>
          </AppConfigProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </Suspense>
  )
}
