import { formatTimestamp } from '@vectroid/shared/utils'
import { Badge } from 'components/catalyst/badge'
import { Card } from 'components/catalyst/card'
import { Divider } from 'components/catalyst/divider'
import { Text } from 'components/catalyst/text'
import { ColumnDefinition, CommonTable } from 'components/common'
import { CommonTooltip } from 'components/common/tooltip/tooltip'
import { CopyButton } from 'components/copy-button'
import { useAsyncData } from 'hooks/use-async-data'
import { DatasetBulkUpload } from 'modules/datasets'
import { DatasetBulkUploadsEmptyState } from 'modules/datasets/components/dataset-bulk-uploads-empty-state'
import { UploadStatus } from 'modules/datasets/const/upload-status.enum'
import { listDatasetBulkUploadsApi } from 'modules/datasets/services'
import { useDatasetDetailStore } from 'modules/datasets/store/datase-detail.store'
import { useDatasetBulkUploadStore } from 'modules/datasets/store/dataset-bulk-upload.store'
import { getDatasetBulkUploadStatusBadgeColor } from 'modules/datasets/utils'
import { getDatasetURL } from 'modules/datasets/utils/get-dataset-url'
import { useEffect } from 'react'

export function DatasetDetailBulkUploadsPage() {
  const dataset = useDatasetDetailStore((state) => state.dataset)
  const uploadStatus = useDatasetBulkUploadStore((state) => state.uploadStatus)
  const inprogressUploadDataset = useDatasetBulkUploadStore((state) => state.dataset)

  const { loading, data, fetchData } = useAsyncData(listDatasetBulkUploadsApi, {
    showErrorMessage: true,
    params: [getDatasetURL(dataset?.uri!), dataset?.name!],
  })

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (inprogressUploadDataset?.id === dataset?.id && uploadStatus === UploadStatus.Completed) {
      fetchData()
    }
  }, [uploadStatus, inprogressUploadDataset])

  const columns: ColumnDefinition<DatasetBulkUpload>[] = [
    {
      key: 'filename',
      header: 'File name',
      cell: (row) => {
        return (
          <CommonTooltip content={row.filename} enabled={row?.filename?.length! > 35}>
            <Text>
              {row?.filename?.slice(0, 35) ?? 'N/A'}
              {row?.filename?.length! > 35 ? '...' : ''}
            </Text>
          </CommonTooltip>
        )
      },
    },
    {
      key: 'status',
      header: 'Status',
      cell: (row) => <Badge color={getDatasetBulkUploadStatusBadgeColor(row.status)}>{row.status}</Badge>,
    },
    {
      key: 'errorMessage',
      header: 'Message',
      cell: (row) => {
        return (
          <CommonTooltip
            content={<p className="max-w-md text-wrap">{row.errorMessage}</p>}
            enabled={row?.errorMessage?.length! > 35}
          >
            <Text>
              {row?.errorMessage?.slice(0, 35) ?? 'N/A'}
              {row?.errorMessage?.length! > 35 ? '...' : ''}
            </Text>
          </CommonTooltip>
        )
      },
    },
    {
      key: 'uploadId',
      header: 'Upload ID',
      cell: (row) => (
        <div className="flex space-x-3">
          <Text>{row.uploadId.slice(0, 12)}...</Text>
          <CopyButton text={row.uploadId} iconOnly />
        </div>
      ),
    },
    {
      key: 'updatedAt',
      header: 'Updated at',
      cell: (row) => (
        <CommonTooltip
          content={
            <div className="flex flex-col items-start space-y-1">
              <Text className="!text-xs">Created at: {formatTimestamp(row.createdAt)}</Text>
              <Text className="!text-xs">Updated at: {formatTimestamp(row.updatedAt)}</Text>
            </div>
          }
        >
          <Text className="!text-xs">{formatTimestamp(row.updatedAt)}</Text>
        </CommonTooltip>
      ),
    },
  ]

  const items = (data?.bulkUploads ?? []).sort((a, b) => b.updatedAt - a.updatedAt)

  return (
    <div className="space-y-6">
      <DatasetBulkUploadsEmptyState />

      {loading || items.length ? (
        <>
          <Divider soft className="!my-8" />
          <Card heading={`Bulk uploads (${items.length})`}>
            <CommonTable<DatasetBulkUpload> columns={columns} data={items} loading={loading} />
          </Card>
        </>
      ) : null}
    </div>
  )
}
